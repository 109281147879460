import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spin, Input, Button, Form, Row, Col, InputNumber, Select, Divider, Tabs, Upload, message } from 'antd';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-build-classic-base64-upload/build/ckeditor';
// import Font from "@ckeditor/ckeditor5-font/src/font";

import { FontSizeOutlined, AlignLeftOutlined, CloudUploadOutlined, LineHeightOutlined, LinkOutlined } from '@ant-design/icons';
import ModalUI from '../../UI/modalUI/modalUI';

const { Option } = Select;
const { Dragger } = Upload;
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
ClassicEditor.defaultConfig = {
    toolbar: {
        items: ['heading', '|', 'bold', 'italic', 'fontSize', 'fontColor', '|', 'bulletedList', 'numberedList', '|', 'insertTable', '|', 'imageUpload', '|', 'undo', 'redo'],
    },
    image: {
        toolbar: ['imageStyle:full', 'imageStyle:side', '|', 'imageTextAlternative'],
    },
    table: {
        contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
    },
    language: 'es',
};

const ModalAddContent = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const showModalCreateContent = useSelector((state) => state.dashboardReducer.showModalCreateContent);
    const fetchingCreateContent = useSelector((state) => state.dashboardReducer.fetchingCreateContent);
    const page = useSelector((state) => state.dashboardReducer.page);
    const search = useSelector((state) => state.dashboardReducer.search);
    const cleanForm = useSelector((state) => state.dashboardReducer.cleanForm);
    const fetchingCreateImages = useSelector((state) => state.dashboardReducer.fetchingCreateImages);
    const [category, setCategory] = useState(null);
    const [fileList, setFileList] = useState(null);
    const [loadingUpload, setLoadinUpload] = useState(false);
    const [htmlEditor, setHtmlEditor] = useState('');
    const [requiredTitulo, setRequiredTitulo] = useState(true);
    const [requiredImagen, setRequiredImagen] = useState(true);

    useEffect(() => {
        if (!showModalCreateContent) {
            form.resetFields();
        }
    }, [showModalCreateContent, form]);

    if (cleanForm === 'cleanAddContent') {
        form.resetFields();
        setFileList([]);
        setHtmlEditor('');
        dispatch({ type: 'CLEAN_FORM', cleanForm: '' });
        setCategory(null);
    }

    const handleCancel = () => {
        form.resetFields();
        setFileList([]);
        setHtmlEditor('');
        dispatch({ type: 'SHOW_MODAL_CREATE_CONTENT', showModalCreateContent: false, page, search });
        setCategory(null);
    };
    console.log('htmlEditor :>> ', htmlEditor);
    const onFinish = (data) => {
        data.htmlEditor = htmlEditor;
        console.log('data', data, htmlEditor);
        if (data.imagen) {
            for (let i in data.imagen.fileList) {
                let reader = new FileReader();
                reader.onload = (e) => {
                    data.imagen.fileList[i].base64 = e.target.result;
                };
                reader.readAsDataURL(data.imagen.fileList[i].originFileObj);
            }
            dispatch({ type: 'CREATE_CONTENT_REQUEST', data, page, search });
        } else {
            dispatch({ type: 'CREATE_CONTENT_REQUEST', data, page, search });
        }
    };

    const onSelectCategory = (category) => {
        console.log('category', category);
        setCategory(category);
    };

    const validateMessages = {
        required: 'Favor de llenar el campo!',
    };
    const onChangeTab = (value) => {
        console.log('value', value);
        if (parseInt(value) === 1) {
            setRequiredImagen(false);
            setRequiredTitulo(true);
            form.setFieldsValue({
                imagen: null,
            });
            setFileList([]);
        } else {
            setRequiredTitulo(false);
            setRequiredImagen(true);
            form.setFieldsValue({
                titulo: null,
            });
        }
    };
    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess('ok');
        }, 1000);
    };

    const props = {
        name: 'file',
        multiple: false,
        accept: '.png, .jpg, .svg, .jpeg',
        customRequest: dummyRequest,
        fileList: fileList,

        onChange(info) {
            const { status } = info.file;
            let fileList = [...info.fileList];

            // 1. Limit the number of uploaded files
            // Only to show two recent uploaded files, and old ones will be replaced by the new
            fileList = fileList.slice(-1);
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            } else {
                setFileList(fileList);
                setLoadinUpload(true);
            }
            if (status === 'done') {
                message.success(`El archivo "${info.file.name}" se subió de manera correcta.`);
                setFileList(fileList);
                setLoadinUpload(false);
            } else if (status === 'error') {
                message.error(`${info.file.name} falló al cargarse.`);
                setFileList(fileList);
                setLoadinUpload(false);
            }
        },
        onRemove() {
            setFileList([]);
        },
    };

    const propsCarrousel = {
        name: 'fileCarrousel',
        multiple: true,
        accept: '.png, .jpg, .svg, .jpeg',
        customRequest: dummyRequest,
    };

    const onChangeImage = (info) => {
        const { status } = info.file;
        let fileList = [...info.fileList];

        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        } else {
            setFileList(fileList);
            setLoadinUpload(true);
        }
        if (status === 'done') {
            message.success(`El archivo "${info.file.name}" se subió de manera correcta.`);
            setFileList(fileList);
            setLoadinUpload(false);
        } else if (status === 'error') {
            message.error(`${info.file.name} falló al cargarse.`);
            setFileList(fileList);
            setLoadinUpload(false);
        }
    };

    return (
        <ModalUI title={'Crear contenido'} open={showModalCreateContent} hideModal={handleCancel} width={900}>
            <Spin spinning={fetchingCreateContent || fetchingCreateImages}>
                <Form {...layout} form={form} onFinish={onFinish} validateMessages={validateMessages}>
                    <Row type='flex' justify='space-around'>
                        <Col span={24}>
                            <Row justify='center'>
                                <Col lg={18} xs={23}>
                                    <Form.Item
                                        name='category'
                                        label='Elige un tipo de contenido'
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Select placeholder='Elige un tipo de contenido' onChange={onSelectCategory}>
                                            <Option value='Menú'>Menú</Option>
                                            <Option value='Submenú'>Submenú</Option>
                                            <Option value='Imagen'>Imagen</Option>
                                            <Option value='Título'>Título</Option>
                                            <Option value='Texto'>Texto</Option>
                                            <Option value='Carrousel'>Carrousel</Option>
                                            <Option value='Nota'>Nota</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={22}>
                                    <Divider orientation='left'>Contenido</Divider>
                                </Col>
                                <Col span={1} offset={1}>
                                    <br />
                                    <Spin spinning={category === null}></Spin>
                                </Col>
                            </Row>
                        </Col>
                        {category !== null ? (
                            <Col span={24}>
                                {category === 'Menú' ? (
                                    <Row justify='space-around'>
                                        <Col lg={7} xs={23}>
                                            <Form.Item
                                                label='Posición'
                                                name='posicion'
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                            >
                                                <InputNumber min={1} />
                                            </Form.Item>
                                        </Col>
                                        <Col lg={16} xs={23}>
                                            <Tabs
                                                tabPosition='left'
                                                defaultActiveKey='1'
                                                centered
                                                onChange={onChangeTab}
                                                items={new Array(2).fill(null).map((_, i) => {
                                                    const id = String(i + 1);
                                                    return {
                                                        label: i === 0 ? 'Título' : 'Imagen',
                                                        key: id,
                                                        children:
                                                            i === 0 ? (
                                                                <Form.Item label='Título' name='titulo' rules={[{ required: requiredTitulo }]}>
                                                                    <Input prefix={<FontSizeOutlined className='iconoPrefix' />} placeholder='Título' />
                                                                </Form.Item>
                                                            ) : (
                                                                <Form.Item label='Imagen' name='imagen' rules={[{ required: requiredImagen }]}>
                                                                    <Dragger {...props}>
                                                                        <p className='ant-upload-drag-icon'>
                                                                            <CloudUploadOutlined />
                                                                        </p>
                                                                        <p className='ant-upload-text'>Clic o arrastra un archivo para cargar</p>
                                                                    </Dragger>
                                                                </Form.Item>
                                                            ),
                                                    };
                                                })}
                                            />
                                        </Col>
                                    </Row>
                                ) : (
                                    <Row justify='space-around'>
                                        <Col lg={11} xs={23}>
                                            <Form.Item
                                                label='Posición'
                                                name='posicion'
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                            >
                                                <InputNumber min={1} />
                                            </Form.Item>
                                        </Col>
                                        {/* {category === 'Carrousel' ? (
                                            <Col lg={11} xs={23}>
                                                <Form.Item
                                                    label='Posición'
                                                    name='posicion'
                                                    rules={[
                                                        {
                                                            required: true,
                                                        },
                                                    ]}
                                                >
                                                    <InputNumber min={1} />
                                                </Form.Item>
                                            </Col>
                                        ) : (
                                            false
                                        )} */}
                                        {category === 'Submenú' ? (
                                            <Col lg={11} xs={23}>
                                                <Form.Item label='Título' name='titulo' rules={[{ required: true }]}>
                                                    <Input prefix={<FontSizeOutlined className='iconoPrefix' />} placeholder='Título' />
                                                </Form.Item>
                                            </Col>
                                        ) : (
                                            false
                                        )}
                                        {category === 'Imagen' ? (
                                            <Col lg={11} xs={23}>
                                                <Form.Item label='Referencia' name='href'>
                                                    <Input prefix={<FontSizeOutlined className='iconoPrefix' />} placeholder='Referencia' />
                                                </Form.Item>
                                            </Col>
                                        ) : (
                                            false
                                        )}
                                        {category === 'Imagen' ? (
                                            <Col lg={11} xs={23}>
                                                <Form.Item label='Imagen' name='imagen' rules={[{ required: true }]}>
                                                    <Dragger {...props}>
                                                        <p className='ant-upload-drag-icon'>
                                                            <CloudUploadOutlined />
                                                        </p>
                                                        <p className='ant-upload-text'>Clic o arrastra un archivo para cargar</p>
                                                    </Dragger>
                                                </Form.Item>
                                            </Col>
                                        ) : (
                                            false
                                        )}
                                        {category === 'Carrousel' ? (
                                            <Col lg={11} xs={23}>
                                                <Form.Item label='Descripción del contenedor' name='descripcion' rules={[{ required: true }]}>
                                                    <Input prefix={<AlignLeftOutlined className='iconoPrefix' />} placeholder='Descripción' />
                                                </Form.Item>
                                            </Col>
                                        ) : (
                                            false
                                        )}
                                        {category === 'Carrousel' ? (
                                            <>
                                                <Col lg={23} xs={23}>
                                                    <Form.Item label='Imagen' name='imagen' rules={[{ required: true }]}>
                                                        <Dragger {...propsCarrousel} onChange={onChangeImage}>
                                                            <p className='ant-upload-drag-icon'>
                                                                <CloudUploadOutlined />
                                                            </p>
                                                            <p className='ant-upload-text'>Clic o arrastra un archivo para cargar</p>
                                                        </Dragger>
                                                    </Form.Item>
                                                </Col>

                                                {fileList ? (
                                                    <Col lg={23} xs={23}>
                                                        {fileList.map((item) => (
                                                            <>
                                                                <Divider style={{ color: '#1677ff' }} orientation='left'>
                                                                    {item.name}
                                                                </Divider>

                                                                <Row justify='space-around'>
                                                                    <Col span={11}>
                                                                        <Form.Item label='Titulo de la imagen' name={[item.name, 'titulo']} rules={[{ required: true, message: 'Favor de llenar el campo' }]}>
                                                                            <Input prefix={<LineHeightOutlined className='iconoPrefix' />} placeholder='Titulo' style={{ width: '100%' }} />
                                                                        </Form.Item>
                                                                    </Col>

                                                                    <Col span={11}>
                                                                        <Form.Item label='Descripción de la imagen' name={[item.name, 'descripcion']} rules={[{ required: true, message: 'Favor de llenar el campo' }]}>
                                                                            <Input prefix={<AlignLeftOutlined className='iconoPrefix' />} maxLength={200} placeholder='Descripción' style={{ width: '100%' }} />
                                                                        </Form.Item>
                                                                    </Col>

                                                                    <Col span={23}>
                                                                        <Form.Item label='URL' name={[item.name, 'href']} rules={[{ required: false, message: 'Favor de llenar el campo' }]}>
                                                                            <Input prefix={<LinkOutlined className='iconoPrefix' />} placeholder='URL' style={{ width: '100%' }} />
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                            </>
                                                        ))}
                                                    </Col>
                                                ) : (
                                                    false
                                                )}
                                            </>
                                        ) : (
                                            false
                                        )}
                                        {category === 'Texto' || category === 'Título' ? (
                                            <Col lg={23} xs={23}>
                                                <Form.Item label={category === 'Título' ? 'Título' : 'Descripción'} name={category === 'Título' ? 'titulo' : 'descripcion'} rules={[{ required: true }]}>
                                                    <CKEditor
                                                        editor={ClassicEditor}
                                                        data={''}
                                                        onChange={(event, editor) => {
                                                            console.log({ event, editor });
                                                        }}
                                                        onBlur={(event, editor) => {
                                                            const data = editor.getData();
                                                            setHtmlEditor(data);
                                                            console.log('Blur.', editor);
                                                            setLoadinUpload(false);
                                                        }}
                                                        onFocus={(event, editor) => {
                                                            console.log('Focus.', editor);
                                                            setLoadinUpload(true);
                                                        }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        ) : (
                                            false
                                        )}
                                    </Row>
                                )}
                            </Col>
                        ) : (
                            false
                        )}
                        <Col lg={10} xs={20} className='marginBoton'>
                            <br />
                            <Button className='btnPrimario' htmlType='submit' disabled={category === null || loadingUpload}>
                                Crear Contenido
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </ModalUI>
    );
};

export default ModalAddContent;
