import { useSelector } from 'react-redux';
import { useState } from 'react';
import { Row, Col, Spin } from 'antd';
import HTMLRenderer from 'react-html-renderer';
import { Title, Space, CircleStyledIcon, ContainerIcon, Container, Card } from './cards.style';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const Cards = () => {
    const [keyActive, setKeyActive] = useState(0);
    const fetchingGetContent = useSelector((state) => state.landingReducer.fetchingGetContent);
    const listContent = useSelector((state) => state.landingReducer.listContent);
    const data = [
        {
            title: 'Desarrollo',
            description: 'Desarrollamos soluciones totalmente personalizadas a partir de un esquema profesional basado en estándares de la industria.',
        },
        {
            title: 'Cloud & Devops',
            description: 'Acompañamos a nuestros clientes en su proceso de adopción de nuevas metodologías ágiles para incrementar su productividad.',
        },
        {
            title: 'Seguridad',
            description: 'Integramos sistemas de seguridad física y lógica que garantizan la protección de datos, información e infraestructura informática.',
        },
        {
            title: 'Consultoría',
            description: 'Asesoramos y acompañamos a nuestros clientes en su camino hacia una transformación y aceleración digital.',
        },
        {
            title: 'Soporte a instalaciones críticas',
            description: 'Desarrollamos estrategias integrales de soporte que optimizan cada una de las etapas de la puesta en marcha de instalaciones críticas.',
        },
    ];

    const leftArrowClick = () => {
        if (keyActive > 0) {
            setKeyActive(keyActive - 1);
        } else {
            setKeyActive(3);
        }
    };
    const rigthArrowClick = () => {
        if (keyActive < 4) {
            setKeyActive(keyActive + 1);
        } else {
            setKeyActive(0);
        }
    };

    return (
        <Spin spinning={fetchingGetContent}>
            <Space id='servicios' />
            <Row justify='space-around' align='middle'>
                {listContent.length ? (
                    <Col span={24}>
                        <Title>
                            <HTMLRenderer html={listContent[19].titulo} />
                        </Title>
                    </Col>
                ) : (
                    false
                )}
            </Row>
            <Row justify='space-around' align='middle'>
                <Col span={23}>
                    <Row justify='space-around' align='middle' gutter={[8, 8]}>
                        {data.map((item, i) => (
                            <Col lg={{ span: 4 }} md={{ span: 9 }} xs={{ span: 24 }} key={i}>
                                <Card isActive={keyActive === i ? true : false} onClick={() => setKeyActive(i)}>
                                    <h2>{item.title}</h2>
                                    <p>{item.description}</p>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Col>
            </Row>
            <Space />
            <Row justify='center' align='middle'>
                <Col>
                    <ContainerIcon>
                        <LeftOutlined onClick={leftArrowClick} />
                    </ContainerIcon>
                </Col>
                <Col>
                    {data.map((item, i) => (
                        <Container key={i}>
                            <CircleStyledIcon isActive={keyActive === i ? true : false} />
                        </Container>
                    ))}
                </Col>
                <Col>
                    <ContainerIcon>
                        <RightOutlined onClick={rigthArrowClick} />
                    </ContainerIcon>
                </Col>
            </Row>
            <br />
            <br />
        </Spin>
    );
};

export default Cards;
