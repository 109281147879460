import styled from "styled-components";

export const IconContainer = styled.div`
    svg {
        width: 20px;
        height: 20px;
        color: white;
    }
    .logoHeader {
        width: 100%;
    }
    ${({ isActive }) => isActive && ` svg { color: #00bbdb !important }`};
    ${({ search }) =>
        search &&
        `width: 100%; display: flex; flex-flow: row wrap; justify-content: flex-end; margin-left: -10px;`};
`;

export const NavbarContainer = styled.div`
    background-color: #2c4977;
    box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.2);
    .title {
        color: white;
        text-align: center;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 0.85rem;
        cursor: pointer;
    }
`;

export const ContainerDivider = styled.div`
    .ant-divider-vertical {
        height: 1.5em;
        margin-top: 5px;
        width: 2px;
        background-color: white;
    }
`;

export const SubTitle = styled.div`
    color: black;
    font-family: "Gotham", sans-serif;
    &:hover {
        color: #203747;
        font-weight: 500;
    }
`;

export const Container = styled.div`
    margin-top: 0.8em;
    background-color: white;
    padding: 20px;
    border-radius: 3px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
`;

export const SmallIconContainer = styled.div`
    ${({ isActive }) => isActive && ` svg { color: #00bbdb !important }`};
    svg {
        color: white;
    }
`;

export const ListNavbar = ({ className, children, isActive }) =>
    isActive ? (
        <ActiveListItem className={className}>{children}</ActiveListItem>
    ) : (
        <NormalListItem className={className}>{children}</NormalListItem>
    );
const NormalListItem = ({ className, children }) => (
    <h3 className={className}>{children}</h3>
);

const ActiveListItem = styled(NormalListItem)`
    color: #00bbdb !important;
`;

export const NavbarResponsive = styled.div`
    background-color: #203747;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
`;
