import axios from 'axios';

const endpoint = 'https://sdigital-web.herokuapp.com/';

//USUARIOS
export function listaUsuariosCall(token, page, search) {
    return axios({
        method: 'get',
        url: endpoint + 'users/search?' + search + 'page=' + page + '&offset=10',
        headers: { 'Content-Type': 'application/json', Authorization: token },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response.data;
        });
}

export function crearUsuarioCall(token, data) {
    return axios({
        method: 'post',
        url: endpoint + 'users/cliente',
        data: data,
        headers: { 'Content-Type': 'application/json', Authorization: token },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response.data;
        });
}

export function editUsersCall(token, idUser, data) {
    return axios({
        method: 'put',
        url: endpoint + 'users?idUser=' + idUser,
        data: data,
        headers: { 'Content-Type': 'application/json', Authorization: token },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response.data;
        });
}

export function estadoUsuariosCall(token, idUser) {
    return axios({
        method: 'get',
        url: endpoint + 'users/desactiva?idUser=' + idUser,
        headers: { 'Content-Type': 'application/json', Authorization: token },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response.data;
        });
}

//CONTENT
export function getContentCall(token, title, page) {
    return axios({
        method: 'get',
        url: endpoint + 'contenido?' + title + 'page=' + page + '&offset=12',
        headers: { 'Content-Type': 'application/json', Authorization: token },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response.data;
        });
}

export function createContentCall(token, data) {
    return axios({
        method: 'post',
        url: endpoint + 'contenido',
        data: data,
        headers: { 'Content-Type': 'application/json', Authorization: token },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response.data;
        });
}

export function updateContentCall(token, idContenido, data) {
    return axios({
        method: 'put',
        url: endpoint + 'contenido?idContenido=' + idContenido,
        data: data,
        headers: { 'Content-Type': 'application/json', Authorization: token },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response.data;
        });
}

export function deleteContentCall(token, idContenido) {
    return axios({
        method: 'delete',
        url: endpoint + 'contenido?idContenido=' + idContenido,
        headers: { 'Content-Type': 'application/json', Authorization: token },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response.data;
        });
}

//IMAGES
export function createImageByContentCall(token, data) {
    return axios({
        method: 'post',
        url: endpoint + 'image',
        data: data,
        headers: { 'Content-Type': 'application/json', Authorization: token },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response.data;
        });
}

export function editImageByContentCall(token, data, idImage) {
    return axios({
        method: 'put',
        url: endpoint + 'image?idImage=' + idImage,
        data: data,
        headers: { 'Content-Type': 'application/json', Authorization: token },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response.data;
        });
}

//NOTES
export function getNotesCall(param, page, offset) {
    return axios({
        method: 'get',
        url: endpoint + 'nota?page=' + page + offset + param,
        headers: {
            'Content-Type': 'application/json',
            // Authorization: token
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response.data;
        });
}

export function createNotesCall(token, data) {
    return axios({
        method: 'post',
        url: endpoint + 'nota',
        data: data,
        headers: { 'Content-Type': 'application/json', Authorization: token },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response.data;
        });
}

export function updateNotesCall(token, idNota, data) {
    return axios({
        method: 'put',
        url: endpoint + 'nota?idNota=' + idNota,
        data: data,
        headers: { 'Content-Type': 'application/json', Authorization: token },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response.data;
        });
}

export function deleteNotesCall(token, idNota) {
    return axios({
        method: 'delete',
        url: endpoint + 'nota?idNota=' + idNota,
        headers: { 'Content-Type': 'application/json', Authorization: token },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response.data;
        });
}
