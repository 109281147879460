import { useSelector } from 'react-redux';
import HTMLRenderer from 'react-html-renderer';
import { Row, Col, Spin } from 'antd';
import { Title, Card, IconContainer, ShadowContainer, Text } from './about.style';

const About = () => {
    const fetchingGetContent = useSelector((state) => state.landingReducer.fetchingGetContent);
    const listContent = useSelector((state) => state.landingReducer.listContent);
    // const components = [
    //     {
    //         title: 'Optimización de procesos',
    //         image: Optimizacion,
    //         alt: 'optimizacion',
    //     },
    //     {
    //         title: 'Flexibilidad en la integración tecnológica',
    //         image: Escalabilidad,
    //         alt: 'Flexibilidad',
    //     },
    //     { title: 'Máxima escalabilidad', image: Escalabilidad, alt: 'Escalabilidad' },
    //     { title: 'Adaptabilidad con las tendencias del mercado', image: Proyectos, alt: 'Adaptabilidad' },
    //     {
    //         title: 'Alta seguridad y transparencia en los procesos del proyecto',
    //         image: Seguridad,
    //         alt: 'Seguridad',
    //     },
    //     {
    //         title: 'Confidencialidad sobre el código',
    //         image: Seguridad,
    //         alt: 'Confidencialidad',
    //     },
    //     { title: 'Ahorro económico a largo plazo', image: Optimizacion, alt: 'Ahorro' },
    //     { title: 'Ahorro de tiempo a corto plazo', image: Optimizacion, alt: 'Tiempo' },
    // ];
    return (
        <Spin spinning={fetchingGetContent}>
            {listContent.length ? (
                <Row justify='space-around' align='middle'>
                    <Col span={24}>
                        <Title>
                            <HTMLRenderer html={'<h1>' + listContent[13].titulo + '</h1>'} />
                        </Title>
                    </Col>
                    <Col span={23}>
                        <Text>
                            <HTMLRenderer html={listContent[14].titulo} />
                        </Text>
                    </Col>
                    {listContent[15].imagenesByIdContenido.map((item, i) => (
                        <Col lg={{ span: 5 }} xs={{ span: 11 }} md={{ span: 11 }} key={i}>
                            <Card>
                                <h3>{item.titulo}</h3>
                                <ShadowContainer>
                                    <IconContainer>
                                        <img src={item.url} alt={item.titulo} />
                                    </IconContainer>
                                </ShadowContainer>
                            </Card>
                        </Col>
                    ))}
                </Row>
            ) : (
                false
            )}
            <br />
            <br />
            <br />
            <br />
        </Spin>
    );
};

export default About;
