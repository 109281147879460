import { takeLatest, call, put } from "redux-saga/effects";
import * as loginActions from "../actions/loginActions";
import { loginCall, usuarioDetalleCall } from "../services/loginServices";

//********************** Login *****************************
function* iniciarSesion(action) {
    try {
        console.log(action);
        const response = yield call(loginCall, action.formulario);
        console.log(response);
        if (response.status === 200) {
            let token = response.headers.authorization;
            //Con el token que nos responde el servicio se manda a llamar el WS para traer el detalle del usuario logeado
            let responseUserInfo = yield call(usuarioDetalleCall, token);
            console.log("response de responseUserInfo");
            console.log(responseUserInfo);

            if (responseUserInfo.status === 200) {
                sessionStorage.setItem("isAuthenticated", true);
                sessionStorage.setItem("token", token);
                sessionStorage.setItem(
                    "userInfo",
                    JSON.stringify(responseUserInfo.data)
                );
                yield put({
                    type: loginActions.LOGIN_API_CALL_SUCCESS,
                    usuario: responseUserInfo.data,
                });
            }
        } else {
            sessionStorage.setItem("isAuthenticated", false);
            let errorMensaje = "";
            if (response.error_message === "Bad credentials") {
                errorMensaje =
                    "Usuario y/o contraseña incorrectas. Por favor intente de nuevo.";
            } else {
                errorMensaje = response.error_message;
            }
            yield put({
                type: loginActions.LOGIN_API_CALL_FAILURE,
                errorMensaje,
            });
        }
    } catch (error) {
        sessionStorage.setItem("isAuthenticated", false);
        console.log(error);
        yield put({
            type: loginActions.LOGIN_API_CALL_FAILURE,
            errorMensaje:
                "Usuario y/o contraseña incorrectas. Por favor intente de nuevo.",
        });
    }
}
export function* iniciarSesionSaga() {
    yield takeLatest(loginActions.LOGIN_API_CALL_REQUEST, iniciarSesion);
}

//********************** Cerrar Sesión *****************************
function* cerrarSesion(action) {
    try {
        console.log(action);
        yield put({ type: loginActions.LOG_OUT_SUCCESS });
        sessionStorage.clear();
        window.location.reload();
    } catch (error) {
        console.log(error);
        yield put({ type: loginActions.LOG_OUT_FAILURE });
    }
}
export function* cerrarSesionSaga() {
    yield takeLatest(loginActions.LOG_OUT_REQUEST, cerrarSesion);
}
