import { useSelector } from 'react-redux';
import { Col, Row, Spin } from "antd";
import { ContainerLogo, IconContainer } from "./contact.style";

const Contact = () => {
    const fetchingGetContent = useSelector((state) => state.landingReducer.fetchingGetContent);
    const listContent = useSelector((state) => state.landingReducer.listContent);
    return (
        <Spin spinning={fetchingGetContent}>
            <ContainerLogo>
                {listContent.length ? (
                <Row justify="space-around" align="middle">
                    <Col lg={{ span: 24 }} xs={{ span: 12 }}>
                        <a href={listContent[5].imagenesByIdContenido[0].href} target="_blank">
                            <img
                                className="imgLogo"
                                src={listContent[5].imagenesByIdContenido[0].url}
                                alt="logo SISSA color"
                            />
                        </a>
                    </Col>
                    <Col lg={{ span: 13 }} xs={{ span: 10 }} >
                        <Row justify="center" align="middle" gutter={[4,4]} >
                            <Col span={7}>
                                <IconContainer>
                                    <a href={listContent[6].imagenesByIdContenido[0].href} target="_blank">
                                        <img
                                            className="imgIcon"
                                            src={listContent[6].imagenesByIdContenido[0].url}
                                            alt="facebook icon"
                                        />
                                    </a>
                                </IconContainer>
                            </Col>
                            {/* <Col span={6}>
                                <IconContainer>
                                <a href={listContent[7].imagenesByIdContenido[0].href} target="_blank">
                                    <img
                                        className="imgIcon"
                                        src={listContent[7].imagenesByIdContenido[0].url}
                                        alt="twitter icon"
                                    />
                                    </a>
                                </IconContainer>
                            </Col> */}
                            <Col span={7}>
                                <IconContainer>
                                <a href={listContent[8].imagenesByIdContenido[0].href} target="_blank">
                                        <img
                                            className="imgIcon"
                                            src={listContent[8].imagenesByIdContenido[0].url}
                                            alt="linkedin icon"
                                        />
                                    </a>
                                </IconContainer>
                            </Col>
                            {/* <Col span={6}>
                                <IconContainer>
                                <a href={listContent[9].imagenesByIdContenido[0].href} target="_blank">
                                    <img
                                        className="imgIcon"
                                        src={listContent[9].imagenesByIdContenido[0].url}
                                        alt="instagram icon"
                                    />
                                    </a>
                                </IconContainer>
                            </Col> */}
                        </Row>
                    </Col>
                </Row>
                ):false}
            </ContainerLogo>
        </Spin>
    );
};

export default Contact;
