/* eslint-disable import/no-anonymous-default-export */
import * as landingActions from '../actions/landingActions';

const initialState = {
    //Content
    fetchingGetContent: false,
    listContent: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        //GET CONTENT
        case landingActions.GET_CONTENT_LANDING_REQUEST:
            return { ...state, fetchingGetContent: true };
        case landingActions.GET_CONTENT_LANDING_SUCCESS:
            return { ...state, fetchingGetContent: false, listContent: action.listContent };
        case landingActions.GET_CONTENT_LANDING_FAILURE:
            return { ...state, fetchingGetContent: false };

        default:
            return state;
    }
};
