import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button, Row, Col } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
const LoginFormulario = () => {
    const dispatch = useDispatch();
    const fetchingLogin = useSelector((state) => state.loginReducer.fetchingLogin);
    const onFinish = (values) => {
        console.log('Success:', values);
        dispatch({ type: 'LOGIN_API_CALL_REQUEST', formulario: values });
    };
    return (
        <Form {...layout} name='login' initialValues={{ remember: true }} onFinish={onFinish}>
            <Form.Item
                label='Usuario'
                name='userName'
                rules={[
                    {
                        required: true,
                        message: 'Favor de utilizar un correo válido',
                        type: 'email',
                    },
                ]}
            >
                <Input className='inputEstilo' prefix={<UserOutlined />} placeholder='ejemplo@sissadigital.com' />
            </Form.Item>

            <Form.Item label='Contraseña' name='password' rules={[{ required: true, message: 'Favor de llenar el campo' }]}>
                <Input.Password className='inputEstilo' prefix={<LockOutlined />} placeholder='Ingresa contraseña' />
            </Form.Item>
            <Form.Item>
                <Row justify='center'>
                    <Col xs={22} md={12}>
                        <br />
                        <Button className='btnPrimario' htmlType='submit' loading={fetchingLogin}>
                            <span>Ingresar</span>
                        </Button>
                    </Col>
                </Row>
            </Form.Item>
        </Form>
    );
};
export default LoginFormulario;
