import { combineReducers } from 'redux';
import loginReducer from './loginReducer';
import dashboardReducer from './dashboardReducer';
import landingReducer from './landingReducer';
const roootReducers = combineReducers({
    loginReducer,
    dashboardReducer,
    landingReducer,
});

export default roootReducers;
