import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spin, Input, Button, Form, Row, Col, Tooltip } from 'antd';
import { LockOutlined, UserOutlined, MailOutlined, SaveOutlined } from '@ant-design/icons';
import ModalUI from '../../UI/modalUI/modalUI';

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

const ModalAddUser = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const showModalCrearUsuario = useSelector((state) => state.dashboardReducer.showModalCrearUsuario);
    const fetchingCrearUsuario = useSelector((state) => state.dashboardReducer.fetchingCrearUsuario);
    const page = useSelector((state) => state.dashboardReducer.page);
    const search = useSelector((state) => state.dashboardReducer.search);

    useEffect(() => {
        if (!showModalCrearUsuario) {
            form.resetFields();
        }
    }, [showModalCrearUsuario, form]);

    const handleCancel = () => {
        form.resetFields();
        dispatch({
            type: 'SHOW_MODAL_CREAR_USUARIO',
            showModalCrearUsuario: false,
            page,
            search,
        });
    };
    const onFinish = (formulario) => {
        form.resetFields();
        console.log('formulario', formulario);
        dispatch({
            type: 'CREAR_USUARIO_REQUEST',
            formulario,
            page,
            search,
        });
    };

    const validateMessages = {
        required: 'Favor de llenar el campo!',
        types: {
            email: 'No es un correo válido',
        },
    };

    return (
        <ModalUI title={'Crear usuario'} open={showModalCrearUsuario} hideModal={handleCancel} width={900}>
            <Spin spinning={fetchingCrearUsuario}>
                <Form {...layout} form={form} onFinish={onFinish} validateMessages={validateMessages}>
                    <Row justify='space-between' gutter={[8, 8]}>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label='Nombre completo'
                                name='name'
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input prefix={<UserOutlined className='iconoPrefix' />} placeholder='Nombre completo' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label='Correo'
                                name='username'
                                rules={[
                                    {
                                        type: 'email',
                                        required: true,
                                    },
                                ]}
                            >
                                <Input prefix={<MailOutlined className='iconoPrefix' />} placeholder='Correo' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label='Contraseña'
                                name='password'
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input.Password prefix={<LockOutlined className='iconoPrefix' />} placeholder='Contraseña' />
                            </Form.Item>
                        </Col>
                        <Col xs={1} lg={1} className='marginBoton'>
                            <br />
                            <Tooltip title='Guardar' placement='left'>
                                <Button className='btnPrimarioIcono' shape='circle' htmlType='submit' icon={<SaveOutlined />} />
                            </Tooltip>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </ModalUI>
    );
};

export default ModalAddUser;
