import styled from 'styled-components';

export const Title = styled.div`
    background-color: #2e3a47;
    color: white;
    border-radius: 10px;
    text-transform: uppercase;
    p {
        font-size: 2rem;
        font-weight: 300;
        text-align: center;
        padding-top: 9px;
    }
    strong {
        font-weight: 600;
    }
    font-family: 'Gotham', sans-serif;
`;

export const Divider = styled.div`
    border: 1px solid #c7cdd1;
    width: 100%;
`;

export const Space = styled.div`
    padding-bottom: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    .container {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        transform-style: preserve-3d;
        perspective: 500px;
    }
    .container .box {
        position: relative;
        width: 100%;
        transition: 0.5s;
        transform-style: preserve-3d;
        overflow: hidden;
    }
    .container:hover .box {
        transform: rotateY(25deg);
    }
    .container .box:hover ~ .box {
        transform: rotateY(-25deg);
    }
    .container .box:hover {
        transform: rotateY(0deg) scale(1.25);
        z-index: 1;
        box-shadow: 0 25px 40px rgba(0, 0, 0, 0.5);
    }
    .container .box .imgBx {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .container .box .content:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, #00bbdb, #000);
        opacity: 0;
        transition: 0.5s;
        mix-blend-mode: multiply;
    }
    .container .box:hover .content:before {
        opacity: 0.8;
    }
    .container .box .imgBx img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        margin-bottom: 10px;
    }
    .container .box .content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        display: flex;
        align-items: flex-end;
        box-sizing: border-box;
        padding-left: 10px;
    }
    .container .box .content h2 {
        color: #fff;
        transition: 0.5s;
        text-transform: uppercase;
        margin-bottom: 5px;
        font-size: 20px;
        transform: translateY(200px);
        transition-delay: 0.3s;
    }
    .container .box:hover .content h2 {
        transform: translateY(0px);
    }
    .container .box .content p {
        color: #fff;
        transition: 0.5s;
        font-size: 14px;
        transform: translateY(200px);
        transition-delay: 0.4s;
    }
    .container .box:hover .content p {
        transform: translateY(0px);
    }
    .cardCarousel {
        border-radius: 2em;
        background-color: #dfe3ec;
        width: 100%;
        color: white;
    }
    .imageReferences {
        height: 20em;
        object-fit: cover;
        border-top-right-radius: 2em !important;
        border-top-left-radius: 2em !important;
    }
`;

export const ImageContainer = styled.div`
    font-family: 'Gotham', sans-serif;
    img {
        width: 100%;
    }
    @media (min-width: 701px) and (max-width: 991px) {
        padding: 10px;
    }

    h5,
    p {
        color: #626767;
        text-align: center;
        margin: 5px;
    }
    h2 {
        margin-top: 10px;
        font-size: 1.2rem;
        margin-bottom: 1em;
    }
    p {
        font-size: 1rem;
    }
`;

export const ContainerCarousel = styled.div`
    .react-multiple-carousel__arrow {
        background-color: #254a74;
        color: white;
        bottom: 50%;
    }
`;
