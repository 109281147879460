import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spin, Input, Button, Form, Row, Col, Upload, Tabs, message, Descriptions, Divider } from 'antd';
import { FontSizeOutlined, AlignLeftOutlined, CloudUploadOutlined, LineHeightOutlined, LinkOutlined } from '@ant-design/icons';
import ModalUI from '../../UI/modalUI/modalUI';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-build-classic-base64-upload/build/ckeditor';

const { Dragger } = Upload;
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

const ModalEditContent = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const showModalEditContent = useSelector((state) => state.dashboardReducer.showModalEditContent);
    const fetchingEditContent = useSelector((state) => state.dashboardReducer.fetchingEditContent);
    const fetchingEditImages = useSelector((state) => state.dashboardReducer.fetchingEditImages);
    const page = useSelector((state) => state.dashboardReducer.page);
    const search = useSelector((state) => state.dashboardReducer.search);
    const selectedContent = useSelector((state) => state.dashboardReducer.selectedContent);
    const [fileList, setFileList] = useState(null);
    const [loadingUpload, setLoadinUpload] = useState(false);
    const [htmlEditor, setHtmlEditor] = useState('');

    useEffect(() => {
        form.setFieldsValue(selectedContent);
        if (!showModalEditContent) {
            form.resetFields();
        }
    }, [showModalEditContent, form, selectedContent]);

    const handleCancel = () => {
        form.resetFields();
        setFileList([]);
        setHtmlEditor('');
        dispatch({ type: 'SHOW_MODAL_EDIT_CONTENT', showModalEditContent: false, page, search });
    };
    const onFinish = (data) => {
        console.log('data', data, htmlEditor);
        data.htmlEditor = htmlEditor;
        if (data.imagen) {
            for (let i in data.imagen.fileList) {
                let reader = new FileReader();
                reader.onload = (e) => {
                    data.imagen.fileList[i].base64 = e.target.result;
                };
                reader.readAsDataURL(data.imagen.fileList[i].originFileObj);
            }
        }
        dispatch({ type: 'UPDATE_CONTENT_REQUEST', selectedContent: selectedContent, data, page, search });
    };

    const onChangeTab = (value) => {
        console.log('value', value);
        if (parseInt(value) === 1) {
            form.setFieldsValue({
                imagen: null,
            });
        } else {
            form.setFieldsValue({
                titulo: null,
            });
        }
    };

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess('ok');
        }, 1000);
    };

    const props = {
        name: 'file',
        multiple: false,
        accept: '.png, .jpg, .svg, .jpeg',
        customRequest: dummyRequest,
        fileList: fileList,
        onChange(info) {
            const { status } = info.file;
            let fileList = [...info.fileList];

            // 1. Limit the number of uploaded files
            // Only to show two recent uploaded files, and old ones will be replaced by the new
            fileList = fileList.slice(-1);
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            } else {
                setFileList(fileList);
                setLoadinUpload(true);
            }
            if (status === 'done') {
                message.success(`El archivo "${info.file.name}" se subió de manera correcta.`);
                setFileList(fileList);
                setLoadinUpload(false);
            } else if (status === 'error') {
                message.error(`${info.file.name} falló al cargarse.`);
                setFileList(fileList);
                setLoadinUpload(false);
            }
        },
        onRemove() {
            setFileList([]);
        },
    };
    const propsCarrousel = {
        name: 'fileCarrousel',
        multiple: true,
        accept: '.png, .jpg, .svg, .jpeg',
        customRequest: dummyRequest,
    };

    const validateMessages = {
        required: 'Favor de llenar el campo!',
    };

    const onChangeImage = (info) => {
        const { status } = info.file;
        let fileList = [...info.fileList];

        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        } else {
            setFileList(fileList);
            setLoadinUpload(true);
        }
        if (status === 'done') {
            message.success(`El archivo "${info.file.name}" se subió de manera correcta.`);
            setFileList(fileList);
            setLoadinUpload(false);
        } else if (status === 'error') {
            message.error(`${info.file.name} falló al cargarse.`);
            setFileList(fileList);
            setLoadinUpload(false);
        }
    };

    return (
        <ModalUI title={'Editar contenido'} open={showModalEditContent} hideModal={handleCancel} width={900}>
            <Spin spinning={fetchingEditContent || fetchingEditImages}>
                {selectedContent && (
                    <Form {...layout} form={form} onFinish={onFinish} initialValues={selectedContent} validateMessages={validateMessages}>
                        <Row type='flex' justify='space-around'>
                            <Col span={24}>
                                <Row>
                                    <Col span={24}>
                                        <Descriptions bordered>
                                            <Descriptions.Item label='Tipo de contenido'>{selectedContent.categoria}</Descriptions.Item>
                                            <Descriptions.Item label='Posición'>{selectedContent.posicion}</Descriptions.Item>
                                        </Descriptions>
                                        <br />
                                    </Col>
                                </Row>
                                {selectedContent.categoria === 'Menú' ? (
                                    <Tabs
                                        tabPosition='left'
                                        defaultActiveKey={selectedContent.titulo ? '1' : '2'}
                                        centered
                                        onChange={onChangeTab}
                                        items={new Array(2).fill(null).map((_, i) => {
                                            const id = String(i + 1);
                                            return {
                                                label: i === 0 ? 'Título' : 'Imagen',
                                                key: id,
                                                children:
                                                    i === 0 ? (
                                                        <Form.Item label='Título' name='titulo' rules={[{ required: true }]}>
                                                            <Input prefix={<FontSizeOutlined className='iconoPrefix' />} placeholder='Título' />
                                                        </Form.Item>
                                                    ) : (
                                                        <Form.Item label='Imagen' name='imagen' rules={[{ required: true }]}>
                                                            <Dragger {...props}>
                                                                <p className='ant-upload-drag-icon'>
                                                                    <CloudUploadOutlined />
                                                                </p>
                                                                <p className='ant-upload-text'>Clic o arrastra un archivo para cargar</p>
                                                            </Dragger>
                                                        </Form.Item>
                                                    ),
                                            };
                                        })}
                                    />
                                ) : (
                                    <Row justify='space-around'>
                                        {selectedContent.categoria === 'Submenú' ? (
                                            <Col lg={11} xs={23}>
                                                <Form.Item label='Título' name='titulo' rules={[{ required: true }]}>
                                                    <Input prefix={<FontSizeOutlined className='iconoPrefix' />} placeholder='Título' />
                                                </Form.Item>
                                            </Col>
                                        ) : (
                                            false
                                        )}
                                        {selectedContent.categoria === 'Imagen' ? (
                                            <Col lg={11} xs={23}>
                                                <Form.Item label='Referencia' name='href'>
                                                    <Input prefix={<FontSizeOutlined className='iconoPrefix' />} placeholder='Referencia' />
                                                </Form.Item>
                                            </Col>
                                        ) : (
                                            false
                                        )}
                                        {selectedContent.categoria === 'Imagen' ? (
                                            <Col lg={11} xs={23}>
                                                <Form.Item label='Imagen' name='imagen' rules={[{ required: true }]}>
                                                    <Dragger {...props}>
                                                        <p className='ant-upload-drag-icon'>
                                                            <CloudUploadOutlined />
                                                        </p>
                                                        <p className='ant-upload-text'>Clic o arrastra un archivo para cargar</p>
                                                    </Dragger>
                                                </Form.Item>
                                            </Col>
                                        ) : (
                                            false
                                        )}
                                        {selectedContent.categoria === 'Carrousel' ? (
                                            <Col lg={23} xs={23}>
                                                <Form.Item label='Descripción del contenedor' name='descripcion' rules={[{ required: true }]}>
                                                    <Input prefix={<AlignLeftOutlined className='iconoPrefix' />} placeholder='Descripción' />
                                                </Form.Item>
                                            </Col>
                                        ) : (
                                            false
                                        )}
                                        {selectedContent.categoria === 'Carrousel' ? (
                                            <>
                                                <Col lg={23} xs={23}>
                                                    <Form.Item label='Imagen' name='imagen' rules={[{ required: true }]}>
                                                        <Dragger {...propsCarrousel} onChange={onChangeImage}>
                                                            <p className='ant-upload-drag-icon'>
                                                                <CloudUploadOutlined />
                                                            </p>
                                                            <p className='ant-upload-text'>Clic o arrastra un archivo para cargar</p>
                                                        </Dragger>
                                                    </Form.Item>
                                                </Col>

                                                {fileList ? (
                                                    <Col lg={23} xs={23}>
                                                        {fileList.map((item) => (
                                                            <>
                                                                <Divider style={{ color: '#1677ff' }} orientation='left'>
                                                                    {item.name}
                                                                </Divider>

                                                                <Row justify='space-around'>
                                                                    <Col span={11}>
                                                                        <Form.Item label='Titulo de la imagen' name={[item.name, 'titulo']} rules={[{ required: true, message: 'Favor de llenar el campo' }]}>
                                                                            <Input prefix={<LineHeightOutlined className='iconoPrefix' />} placeholder='Titulo' style={{ width: '100%' }} />
                                                                        </Form.Item>
                                                                    </Col>

                                                                    <Col span={11}>
                                                                        <Form.Item label='Descripción de la imagen' name={[item.name, 'descripcion']} rules={[{ required: true, message: 'Favor de llenar el campo' }]}>
                                                                            <Input prefix={<AlignLeftOutlined className='iconoPrefix' />} maxLength={200} placeholder='Descripción' style={{ width: '100%' }} />
                                                                        </Form.Item>
                                                                    </Col>

                                                                    <Col span={23}>
                                                                        <Form.Item label='URL' name={[item.name, 'href']} rules={[{ required: false, message: 'Favor de llenar el campo' }]}>
                                                                            <Input prefix={<LinkOutlined className='iconoPrefix' />} placeholder='URL' style={{ width: '100%' }} />
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                            </>
                                                        ))}
                                                    </Col>
                                                ) : (
                                                    false
                                                )}
                                            </>
                                        ) : (
                                            false
                                        )}
                                        {selectedContent.categoria === 'Texto' || selectedContent.categoria === 'Título' ? (
                                            <Col lg={23} xs={23}>
                                                <Form.Item label='Descripción' name='descripcion' rules={[{ required: true }]}>
                                                    <CKEditor
                                                        editor={ClassicEditor}
                                                        data={selectedContent.categoria === 'Título' ? selectedContent.titulo : selectedContent.descripcion}
                                                        onChange={(event, editor) => {
                                                            console.log({ event, editor });
                                                        }}
                                                        onBlur={(event, editor) => {
                                                            const data = editor.getData();
                                                            setHtmlEditor(data);
                                                            console.log('Blur.', editor);
                                                            setLoadinUpload(false);
                                                        }}
                                                        onFocus={(event, editor) => {
                                                            console.log('Focus.', editor);
                                                            setLoadinUpload(true);
                                                        }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        ) : (
                                            false
                                        )}
                                        {selectedContent.categoria === 'Submenú' ? (
                                            <Col lg={11} xs={23}>
                                                <Form.Item label='Descripción' name='descripcion' rules={[{ required: true }]}>
                                                    <Input prefix={<AlignLeftOutlined className='iconoPrefix' />} placeholder='Descripción' />
                                                </Form.Item>
                                            </Col>
                                        ) : (
                                            false
                                        )}
                                    </Row>
                                )}
                            </Col>
                            <Col lg={10} xs={20} className='marginBoton'>
                                <br />
                                <Button className='btnPrimario' htmlType='submit' disabled={loadingUpload}>
                                    Editar Contenido
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Spin>
        </ModalUI>
    );
};

export default ModalEditContent;
