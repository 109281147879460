import { useDispatch } from "react-redux";
import { LogoutOutlined } from "@ant-design/icons";
import { Container, IconContainer } from "./navbarResponsive.style";
import LogoSISSA from "../../../../assets/logos/logotipo_SISSA_DIGITAL_blanco_completo.svg";
import { Col, Row, Tooltip } from "antd";
import "./style.css";
const NavbarResponsive = () => {
    const dispatch = useDispatch();

    const onCerrarSesion = () => {
        dispatch({ type: "LOG_OUT_REQUEST" });
    };
    return (
        <>
            <Container>
                <Row justify="start" align="middle">
                    <Col xs={20} offset={1}>
                        <IconContainer>
                            <img
                                src={LogoSISSA}
                                className="logoHeader"
                                alt="Logo SISSA"
                            />
                        </IconContainer>
                    </Col>
                    <Col xs={3}>
                        <IconContainer search>
                            <Tooltip placement="left" title={"Cerrar sesión"}>
                                <LogoutOutlined
                                    className="iconHover"
                                    onClick={onCerrarSesion}
                                />
                            </Tooltip>
                        </IconContainer>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default NavbarResponsive;
