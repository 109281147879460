import { useSelector } from 'react-redux';
import { Col, Row, Divider, Spin } from "antd";
import HTMLRenderer from 'react-html-renderer';
import { ContainerDivider, Title, Subtitle } from "./text.style";

const Text = () => {
    const fetchingGetContent = useSelector((state) => state.landingReducer.fetchingGetContent);
    const listContent = useSelector((state) => state.landingReducer.listContent);
    return (
        <Spin spinning={fetchingGetContent}>
            {listContent.length ? (
            <Row justify="space-around" align="middle" id='nosotros'>
                <Col lg={{ span: 22 }} xs={{ span: 0 }}>
                    <br />
                    <ContainerDivider>
                        <Divider />
                    </ContainerDivider>
                </Col>

                <Col
                    xl={{ span: 18, offset: 1 }}
                    lg={{ span: 22, offset: 1 }}
                    xs={{ span: 22 }}
                >
                    <br />
                    <br />
                    <Row justify="space-around" align="middle">
                        <Col
                            xl={{ span: 6 }}
                            lg={{ span: 8 }}
                            xs={{ span: 20 }}
                            md={{ span: 12 }}
                        >
                            <Title>
                                <HTMLRenderer html={listContent[11].titulo} />
                            </Title>
                        </Col>
                        <Col
                            xl={{ span: 1, offset: 2 }}
                            lg={{ span: 1, offset: 1 }}
                            xs={{ span: 0 }}
                        >
                            <ContainerDivider>
                                <Divider type="vertical" />
                            </ContainerDivider>
                        </Col>
                        <Col
                            xl={{ span: 15 }}
                            lg={{ span: 14 }}
                            xs={{ span: 20 }}
                            md={{ span: 23 }}
                        >
                            <Subtitle>
                                <HTMLRenderer html={listContent[12].titulo} />
                            </Subtitle>
                        </Col>
                    </Row>
                </Col>
                <br />
                <br />
            </Row>
            ):false}
        </Spin>
    );
};

export default Text;
