import styled from 'styled-components';

export const Title = styled.div`
    background-color: #dfe3ec;
    font-family: 'Gotham', sans-serif;
    color: black;
    width: 100%;
    text-transform: uppercase;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
    padding-top: 0.3em;
    padding-bottom: 0.3em;
    margin-bottom: 20px;
    p {
        font-size: 2rem;
        font-weight: 300;
        text-align: center;
        @media screen and (max-width: 400px) {
            font-size: 1.5rem;
        }
    }
    strong {
        font-weight: 800;
        color: #144976;
    }
`;

export const Space = styled.div`
    margin-top: 3em;
`;

export const Card = styled.div`
    background-color: white;
    border: 1px solid #c7c9cc;
    width: 100%;
    border-radius: 5px;
    height: 200px;
    text-align: center;
    margin-top: 10px;
    ${(props) => props.isActive && 'background-color: #254a74; color: white; width:100%; transition-duration: 0.7s;'};
    h2 {
        font-size: 1.2rem;
        font-weight: 300px;
        font-family: 'Gotham Light', sans-serif;
        margin-bottom: 2em;
    }
    p {
        padding: 5px;
        font-size: 1rem;
        font-weight: 200;
    }
`;

export const CircleStyledIcon = styled.div`
    border: 1px solid black;
    border-radius: 50px;
    margin-left: 0.5em;
    margin-right: 0.5em;
    width: 3px;
    height: 3px;

    ${(props) => props.isActive && 'background-color: #2e3a47; width: 4px; height: 4px; transition-duration: 0.7s;'};
`;

export const ContainerIcon = styled.div`
    svg {
        width: 30px;
        height: 30px;
        color: #2e3a47;
    }
`;

export const Container = styled.div`
    display: inline-block;
`;
