export const SHOW_ALERTA_MSG = 'SHOW_ALERTA_MSG';
export const CHANGE_PAGE_VALUE = 'CHANGE_PAGE_VALUE';
export const CHANGE_SEARCH_VALUE = 'CHANGE_SEARCH_VALUE';
export const CLEAN_FORM = 'CLEAN_FORM';
//USERS
export const OBTENER_USUARIOS_REQUEST = 'OBTENER_USUARIOS_REQUEST';
export const OBTENER_USUARIOS_SUCCESS = 'OBTENER_USUARIOS_SUCCESS';
export const OBTENER_USUARIOS_FAILURE = 'OBTENER_USUARIOS_FAILURE';

export const SHOW_MODAL_CREAR_USUARIO = 'SHOW_MODAL_CREAR_USUARIO';

export const CREAR_USUARIO_REQUEST = 'CREAR_USUARIO_REQUEST';
export const CREAR_USUARIO_SUCCESS = 'CREAR_USUARIO_SUCCESS';
export const CREAR_USUARIO_FAILURE = 'CREAR_USUARIO_FAILURE';

export const SHOW_MODAL_EDIT_USER = 'SHOW_MODAL_EDIT_USER';

export const EDIT_USER_REQUEST = 'EDIT_USER_REQUEST';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_FAILURE = 'EDIT_USER_FAILURE';

export const ESTADO_USUARIO_REQUEST = 'ESTADO_USUARIO_REQUEST';
export const ESTADO_USUARIO_SUCCESS = 'ESTADO_USUARIO_SUCCESS';
export const ESTADO_USUARIO_FAILURE = 'ESTADO_USUARIO_FAILURE';

//CONTENT
export const GET_CONTENT_REQUEST = 'GET_CONTENT_REQUEST';
export const GET_CONTENT_SUCCESS = 'GET_CONTENT_SUCCESS';
export const GET_CONTENT_FAILURE = 'GET_CONTENT_FAILURE';

export const SHOW_MODAL_CREATE_CONTENT = 'SHOW_MODAL_CREATE_CONTENT';
export const SHOW_MODAL_EDIT_CONTENT = 'SHOW_MODAL_EDIT_CONTENT';

export const CREATE_CONTENT_REQUEST = 'CREATE_CONTENT_REQUEST';
export const CREATE_CONTENT_SUCCESS = 'CREATE_CONTENT_SUCCESS';
export const CREATE_CONTENT_FAILURE = 'CREATE_CONTENT_FAILURE';

export const UPDATE_CONTENT_REQUEST = 'UPDATE_CONTENT_REQUEST';
export const UPDATE_CONTENT_SUCCESS = 'UPDATE_CONTENT_SUCCESS';
export const UPDATE_CONTENT_FAILURE = 'UPDATE_CONTENT_FAILURE';

export const DELETE_CONTENT_REQUEST = 'DELETE_CONTENT_REQUEST';
export const DELETE_CONTENT_SUCCESS = 'DELETE_CONTENT_SUCCESS';
export const DELETE_CONTENT_FAILURE = 'DELETE_CONTENT_FAILURE';

//IMAGES
export const GET_IMAGES_BY_CONTENT_REQUEST = 'GET_IMAGES_BY_CONTENT_REQUEST';
export const GET_IMAGES_BY_CONTENT_SUCCESS = 'GET_IMAGES_BY_CONTENT_SUCCESS';
export const GET_IMAGES_BY_CONTENT_FAILURE = 'GET_IMAGES_BY_CONTENT_FAILURE';

export const CREATE_IMAGE_BY_CONTENT_REQUEST = 'CREATE_IMAGE_BY_CONTENT_REQUEST';
export const CREATE_IMAGE_BY_CONTENT_SUCCESS = 'CREATE_IMAGE_BY_CONTENT_SUCCESS';
export const CREATE_IMAGE_BY_CONTENT_FAILURE = 'CREATE_IMAGE_BY_CONTENT_FAILURE';

export const EDIT_IMAGE_BY_CONTENT_REQUEST = 'EDIT_IMAGE_BY_CONTENT_REQUEST';
export const EDIT_IMAGE_BY_CONTENT_SUCCESS = 'EDIT_IMAGE_BY_CONTENT_SUCCESS';
export const EDIT_IMAGE_BY_CONTENT_FAILURE = 'EDIT_IMAGE_BY_CONTENT_FAILURE';

//NOTES
export const GET_NOTES_REQUEST = 'GET_NOTES_REQUEST';
export const GET_NOTES_SUCCESS = 'GET_NOTES_SUCCESS';
export const GET_NOTES_FAILURE = 'GET_NOTES_FAILURE';

export const GET_NOTE_SLUG_REQUEST = 'GET_NOTE_SLUG_REQUEST';
export const GET_NOTE_SLUG_SUCCESS = 'GET_NOTE_SLUG_SUCCESS';
export const GET_NOTE_SLUG_FAILURE = 'GET_NOTE_SLUG_FAILURE';

export const SHOW_MODAL_CREATE_NOTES = 'SHOW_MODAL_CREATE_NOTES';
export const SHOW_MODAL_EDIT_NOTES = 'SHOW_MODAL_EDIT_NOTES';

export const CREATE_NOTES_REQUEST = 'CREATE_NOTES_REQUEST';
export const CREATE_NOTES_SUCCESS = 'CREATE_NOTES_SUCCESS';
export const CREATE_NOTES_FAILURE = 'CREATE_NOTES_FAILURE';

export const UPDATE_NOTES_REQUEST = 'UPDATE_NOTES_REQUEST';
export const UPDATE_NOTES_SUCCESS = 'UPDATE_NOTES_SUCCESS';
export const UPDATE_NOTES_FAILURE = 'UPDATE_NOTES_FAILURE';

export const DELETE_NOTES_REQUEST = 'DELETE_NOTES_REQUEST';
export const DELETE_NOTES_SUCCESS = 'DELETE_NOTES_SUCCESS';
export const DELETE_NOTES_FAILURE = 'DELETE_NOTES_FAILURE';
