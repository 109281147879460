import styled from 'styled-components';

export const Title = styled.div`
    font-family: 'Gotham', sans-serif;
    color: black;
    text-transform: uppercase;
    margin-top: 1em;
    text-align: center;
    @media screen and (max-width: 350px) {
        font-size: 1rem;
    }
    @media screen and (min-width: 400px) and (max-width: 600px) {
        font-size: 1.2rem;
    }
    font-size: 1.8rem;
    h1 {
        font-weight: 300;
        text-align: center;
        padding: 5px;
        @media screen and (min-width: 400px) and (max-width: 600px) {
            font-size: 1.2rem;
        }
    }
    strong {
        font-weight: 800;
        color: #144976;
    }
`;

export const Card = styled.div`
    border-radius: 7px;
    background-image: linear-gradient(-90deg, #dfe5eb, #a67c9d), linear-gradient(-90deg, #dfe5eb, #a67c9d);
    background-size: 100% 1px;
    background-position: 0 0, 0 100%;
    background-repeat: no-repeat;
    border-left: 1px solid #a67c9d;
    border-right: 1px solid #dfe5eb;

    font-family: 'Gotham', sans-serif;
    width: 100%;
    .quote {
        padding: 0.7em;
        margin-top: 3em;
        @media screen and (max-width: 1020px) {
            font-size: 0.9rem;
        }
        font-size: 1.2rem;
        font-weight: 100;
    }
    h3 {
        font-size: 1.5rem;
        margin-top: 1.5em;
    }
    .description {
        font-size: 1rem;
        margin-top: -0.7em;
        font-weight: 200;
    }
    .logo {
        width: 100%;
    }
`;

export const Space = styled.div`
    margin-bottom: 3em;
`;

export const Divider = styled.div`
    border: 1px solid #c7cdd1;
    width: 100%;
`;

export const IconContainer = styled.div`
    border-radius: 10px;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #50c2e0;
    .anticon {
        svg {
            width: 25px;
            height: 25px;
        }
        color: white;
    }
`;
export const BottomContainer = styled.div`
    width: 50%;
    transform: translate(50%, 50%);
    position: absolute;
    bottom: 0;
`;

export const ContainerCarousel = styled.div`
    .react-multiple-carousel__arrow {
        background-color: #254a74;
        color: white;
        bottom: 50%;
    }
    .coverCard {
        height: 20em;
        object-fit: cover;
    }
`;
