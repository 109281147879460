import { useSelector } from 'react-redux';
import { useState } from 'react';
import { Row, Col, Spin } from 'antd';
import HTMLRenderer from 'react-html-renderer';
import { Title, Space, NumberTitle, Description, ContainerText, CircleStyledIcon, ContainerIcon, Container } from './results.style';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const Results = () => {
    const [keyActive, setKeyActive] = useState(0);
    const fetchingGetContent = useSelector((state) => state.landingReducer.fetchingGetContent);
    const listContent = useSelector((state) => state.landingReducer.listContent);
    const data = [
        {
            number: 'IXMAKI',
            key: 0,
            color: 'black',
            description: '+100,000 perfiles registrados cada día',
        },
        {
            number: 'VSS',
            key: 1,
            color: 'blue',
            description: '+25,000 input & output por minuto',
        },
        {
            number: 'AppController',
            key: 2,
            color: 'black',
            description: '+1,000 eventos realizados cada día',
        },
        {
            number: 'KaliConnect',
            key: 3,
            color: 'blue',
            description: '0 segundos de latencia',
        },
    ];

    const leftArrowClick = () => {
        if (keyActive > 0) {
            setKeyActive(keyActive - 1);
        } else {
            setKeyActive(3);
        }
    };
    const rigthArrowClick = () => {
        if (keyActive < 3) {
            setKeyActive(keyActive + 1);
        } else {
            setKeyActive(0);
        }
    };

    return (
        <div id='productos'>
            <Spin spinning={fetchingGetContent}>
                <Space />
                {listContent.length ? (
                    <Row justify='space-around' align='middle'>
                        <Col lg={{ span: 15, offset: 1 }} xs={{ span: 18 }}>
                            <Title>
                                <HTMLRenderer html={listContent[16].titulo} />
                            </Title>
                        </Col>
                        <Col lg={{ span: 8 }} xs={{ span: 0 }}>
                            <hr />
                        </Col>
                    </Row>
                ) : (
                    false
                )}
                <Row justify='center' align='middle'>
                    <Col span={23}>
                        <Row justify='center' align='middle'>
                            {data.map((item, i) => (
                                <Col lg={{ span: 6 }} xs={{ span: 12 }} key={i}>
                                    <ContainerText isActive={keyActive === i ? true : false} onClick={() => setKeyActive(i)}>
                                        <NumberTitle isActive={keyActive === i ? true : false} type={item.color}>
                                            {item.number}
                                        </NumberTitle>
                                        <Description>{item.description}</Description>
                                    </ContainerText>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                </Row>
                <Space />
                <Row justify='center' align='middle'>
                    <Col>
                        <ContainerIcon>
                            <LeftOutlined onClick={leftArrowClick} />
                        </ContainerIcon>
                    </Col>
                    <Col>
                        {data.map((item, i) => (
                            <Container key={i}>
                                <CircleStyledIcon isActive={keyActive === i ? true : false} />
                            </Container>
                        ))}
                    </Col>
                    <Col>
                        <ContainerIcon>
                            <RightOutlined onClick={rigthArrowClick} />
                        </ContainerIcon>
                    </Col>
                </Row>
                <br />
                <br />
            </Spin>
        </div>
    );
};

export default Results;
