import axios from "axios";

const endpoint = "https://sdigital-web.herokuapp.com/";

export function loginCall(formulario) {
    return axios({
        method: "post",
        url: endpoint + "login",
        data: {
            username: formulario.userName,
            password: formulario.password,
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response.data;
        });
}

export function usuarioDetalleCall(token) {
    return axios({
        method: "get",
        url: endpoint + "users",
        headers: { "Content-Type": "application/json", Authorization: token },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response.data;
        });
}

export function editUserCall(idUser, data) {
    return axios({
        method: "put",
        url: endpoint + "users?idUser=" + idUser,
        data: data,
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response.data;
        });
}
