/* eslint-disable import/no-anonymous-default-export */
import * as loginActions from "../actions/loginActions";

const initialState = {
    test: false,
    isAuthenticated: false,
    errorLogin: false,
    errorMensaje: "",
    //Login
    fetchingLogin: false,
    usuario: sessionStorage.getItem("userInfo"),
    fetchingLogout: false,
};

const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        //LOGIN
        case loginActions.LOGIN_API_CALL_REQUEST:
            return { ...state, fetchingLogin: true, errorLogin: false };
        case loginActions.LOGIN_API_CALL_SUCCESS:
            return {
                ...state,
                fetchingLogin: false,
                usuario: action.usuario,
                isAuthenticated: true,
                errorLogin: false,
            };
        case loginActions.LOGIN_API_CALL_FAILURE:
            return {
                ...state,
                fetchingLogin: false,
                errorLogin: true,
                errorMensaje: action.errorMensaje,
            };

        case loginActions.LOG_OUT_REQUEST:
            return { ...state, fetchingLogout: true };
        case loginActions.LOG_OUT_SUCCESS:
            return { ...state, fetchingLogout: false };
        case loginActions.LOG_OUT_FAILURE:
            return { ...state, fetchingLogout: false };
        default:
            return state;
    }
};

export default loginReducer;
