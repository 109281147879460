import { useSelector } from 'react-redux';
import Header from '../../layouts/header/headerDashboard';
import { Row, Col, Spin, Tabs, Tooltip } from 'antd';
import { UserOutlined, DiffOutlined, FormOutlined } from '@ant-design/icons';
import './style.css';
import UserContainer from '../../components/administrador/tableUser/tableUser';
import Alerta from './Alerta';
import Content from '../../components/administrador/content/content';
import Notes from '../../components/administrador/notes/notes';

const DashboardContainer = () => {
    const fetchingLogout = useSelector((state) => state.loginReducer.fetchingLogout);
    const tabs = [
        { icono: UserOutlined, titulo: 'Usuarios' },
        { icono: DiffOutlined, titulo: 'Contenedor' },
        { icono: FormOutlined, titulo: 'Notas' },
    ];
    return (
        <div style={{ backgroundColor: '#f2f2f2', minHeight: '100vh' }}>
            <Spin spinning={fetchingLogout}>
                <Header />
                <br />
                <Row justify='space-around'>
                    <Col span={23}>
                        <Tabs
                            centered
                            className='tabDashboard'
                            tabBarStyle={{
                                backgroundColor: '#e4e4e4',
                                borderRadius: '1.5em',
                            }}
                            items={tabs.map((tab, i) => {
                                const id = String(i + 1);
                                return {
                                    label: (
                                        <Tooltip placement='top' title={tab.titulo}>
                                            <tab.icono className='efectoTab' />
                                        </Tooltip>
                                    ),
                                    key: id,
                                    children: <div className='divCard'>{id === '1' ? <UserContainer /> : id === '2' ? <Content /> : <Notes />}</div>,
                                };
                            })}
                        />
                    </Col>
                </Row>
                <Alerta />
            </Spin>
        </div>
    );
};

export default DashboardContainer;
