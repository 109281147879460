import styled from 'styled-components';

export const Title = styled.div`
    background-color: #2e3a47;
    color: white;
    border-radius: 10px;
    text-transform: uppercase;
    text-align: center;
    p {
        font-size: 2rem;
        font-weight: 300;
        text-align: center;
        padding: 5px;
    }
    strong {
        font-weight: 600;
    }
`;

export const Space = styled.div`
    margin-top: 3em;
`;

export const NumberTitle = styled.div`
    color: ${(props) => (props.type === 'blue' ? '#50c2e0' : '#191e25')};
    color: ${(props) => props.isActive && 'white'};
    font-size: 2rem;
    text-align: center;
    @media (max-width: 1000px) {
        font-size: 1.5rem;
    }
    font-family: 'Gotham Black', sans-serif;
`;

export const Description = styled.div`
    margin-top: 10px;
    text-align: center;
    font-family: 'Gotham Light', sans-serif;
    font-size: 1rem;
    @media (max-width: 1000px) {
        font-size: 0.7rem;
        padding-bottom: 4em;
    }
`;

export const Divider = styled.div`
    width: 1px;
    background-color: #191e25;
    height: 120px;
    text-align: center;
    align-items: center;
    color: #2e3a47;
    visibility: ${(props) => props.position === 3 && 'hidden'};
`;

export const ContainerText = styled.div`
    border-radius: 7px;
    padding-top: 10px;
    padding-bottom: 10px;
    @media (max-width: 1000px) {
        ${(props) => props.isActive && ' transition-duration: 0.7s;  '};
    }

    ${(props) => props.isActive && 'background-color: #00bbdb; transition-duration: 0.7s;  color: white; width:100%; '};
`;

export const CircleStyledIcon = styled.div`
    border: 1px solid black;
    border-radius: 50px;
    margin-left: 0.5em;
    margin-right: 0.5em;
    width: 3px;
    height: 3px;

    ${(props) => props.isActive && 'background-color: #2e3a47; width: 4px; height: 4px;'};
`;

export const ContainerIcon = styled.div`
    svg {
        width: 30px;
        height: 30px;
        color: #2e3a47;
    }
`;

export const Container = styled.div`
    display: inline-block;
`;
