import { takeLatest, call, put } from 'redux-saga/effects';
import * as dashboardActions from '../actions/dashboardActions';
import * as landingActions from '../actions/landingActions';
import { getContentCall } from '../services/landingServices';

//********************** GET CONTENT *****************************
function* getContentLanding(action) {
    try {
        console.log(action);
        const page = 'page=0&';
        const offset = 'offset=30';
        const title = '';
        const response = yield call(getContentCall, page, offset, title);
        console.log(response);
        if (response.status === 200) {
            yield put({ type: landingActions.GET_CONTENT_LANDING_SUCCESS, listContent: response.data.data.reverse() });
        } else {
            yield put({ type: dashboardActions.SHOW_ALERTA_MSG, showAlerta: true, tipoAlerta: 'error', textoAlerta: response.message });
            yield put({ type: landingActions.GET_CONTENT_LANDING_FAILURE });
        }
    } catch (error) {
        console.log(error);
        yield put({ type: landingActions.GET_CONTENT_LANDING_FAILURE, errorMensaje: 'Ocurrió un error, favor de intentar más tarde.' });
    }
}
export function* getContentLandingSaga() {
    yield takeLatest(landingActions.GET_CONTENT_LANDING_REQUEST, getContentLanding);
}
