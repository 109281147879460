import styled from "styled-components";

export const CardNotes = styled.div`
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
    font-family: "Gotham", sans-serif;
    text-align: left;

    .category {
        color: #2d4977;
    }
    .title {
        font-size: 1.3rem;
        font-weight: 500;
        text-align: center;
        text-transform: uppercase;
    }
    .date {
        color: #adadaf;
        text-align: center;
    }

    .category,
    .date {
        padding-left: 1em;
        font-weight: 200;
        font-size: 0.8rem;
    }

    .description {
        font-size: 1rem;
        margin-top: -0.5em;
        margin-left: 1em;
    }
`;

export const IconEdit = styled.div`
    margin-top: 1em;
    text-align: right;

    svg {
        width: 18px;
        height: 18px;
        color: #2d4977;
        border: 1px #2d4977 solid;
        border-radius: 50%;
        padding: 5px;

        &:hover {
            background-color: #2d4977;
            color: white;
            padding: 6px;
        }
    }
`;

export const IconDelete = styled.div`
    margin-top: 1em;
    text-align: right;

    svg {
        width: 18px;
        height: 18px;
        color: #2d4977;
        border: 1px #2d4977 solid;
        border-radius: 50%;
        padding: 5px;

        &:hover {
            background-color: #f83535;
            border: 1px #f83535 solid;
            color: white;
            padding: 6px;
        }
    }
`;
