import { useDispatch } from 'react-redux';
import { LogoutOutlined } from '@ant-design/icons';
import { IconContainer, ListNavbar, NavbarContainer } from './navbar.style';
import LogoSISSA from '../../../../assets/logos/logotipo_SISSA_DIGITAL_blanco_completo.svg';
import { Col, Row, Tooltip, Avatar } from 'antd';
const Navbar = () => {
    const dispatch = useDispatch();
    const onCerrarSesion = () => {
        dispatch({ type: 'LOG_OUT_REQUEST' });
    };

    let userInfo = sessionStorage.getItem('userInfo');
    if (userInfo[0]) {
        userInfo = JSON.parse(userInfo);
    }

    return (
        <NavbarContainer>
            <Row justify='space-around' align='middle' gutter={[0, 0]}>
                <Col span={2}></Col>
                <Col span={20}>
                    <Row justify={'center'} align='middle'>
                        <Col span={2}>
                            <IconContainer>
                                <img src={LogoSISSA} className='logoHeader' alt='Logo SISSA' />
                            </IconContainer>
                        </Col>
                    </Row>
                </Col>

                <Col span={1}>
                    <Tooltip placement='left' title={userInfo.name}>
                        <Avatar style={{ background: '#fff', color: '#2c4977' }}>{userInfo.name.split(' ')[1] ? userInfo.name.split(' ')[0][0] + userInfo.name.split(' ')[1][0] : userInfo.name[0]}</Avatar>
                    </Tooltip>
                </Col>

                <Col span={1}>
                    <ListNavbar className='title'>
                        <IconContainer search>
                            <Tooltip placement='left' title={'Cerrar sesión'}>
                                <LogoutOutlined className='iconHover' onClick={onCerrarSesion} />
                            </Tooltip>
                        </IconContainer>
                    </ListNavbar>
                </Col>
            </Row>
        </NavbarContainer>
    );
};

export default Navbar;
