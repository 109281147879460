import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Badge, Card, Spin, Tooltip, Pagination } from 'antd';
import { Helmet } from 'react-helmet';
import HTMLRenderer from 'react-html-renderer';
import Header from '../../layouts/header/header';
import moment from 'moment';
import Footer from '../../layouts/footer/footer';
import LogoSISSA from '../../assets/logos/logoAnimation.svg';

const { Meta } = Card;

const BlogPage = (props) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch({ type: 'GET_NOTE_SLUG_REQUEST', page: 1, param: props.slug, offset: 3 });
    }, []);
    useEffect(() => {
        dispatch({ type: 'GET_NOTES_REQUEST', page: 1, offset: 3 });
    }, []);
    useEffect(() => {
        dispatch({ type: 'GET_CONTENT_LANDING_REQUEST' });
    }, []);

    const [page, setPage] = useState(1);

    const fetchingListNoteSlug = useSelector((state) => state.dashboardReducer.fetchingListNoteSlug);
    const listNoteSlug = useSelector((state) => state.dashboardReducer.listNoteSlug);

    const listNotes = useSelector((state) => state.dashboardReducer.listNotes);
    const fetchingListNotes = useSelector((state) => state.dashboardReducer.fetchingListNotes);
    const listNotesQty = useSelector((state) => state.dashboardReducer.listNotesQty);
    const fetchingGetContent = useSelector((state) => state.landingReducer.fetchingGetContent);

    console.log('listNotesQty :>> ', listNotesQty);
    const onChangePage = (page) => {
        console.log('page :>> ', page);
        setPage(page);
        dispatch({ type: 'GET_NOTES_REQUEST', page, offset: 3 });
    };
    return (
        <>
            {listNoteSlug.length ? (
                <div>
                    <Helmet>
                        <title>{listNoteSlug[0].titulo}</title>
                        <meta name='description' content={listNoteSlug[0].descripcion} data-react-helmet='true' />
                        <link rel='apple-touch-icon' href={listNoteSlug[0].imagen} />
                        <link rel='apple-touch-icon' sizes='72x72' href={listNoteSlug[0].imagen} />

                        <meta property='og:type' content='article' data-react-helmet='true' />
                        <meta property='og:title' content={listNoteSlug[0].titulo} data-react-helmet='true' />
                        <meta property='og:description' content={listNoteSlug[0].descripcion} data-react-helmet='true' />
                        <meta property='og:image' content={listNoteSlug[0].imagen} data-react-helmet='true' />
                        <meta property='og:card' content='summary_large_image' data-react-helmet='true' />

                        <meta name='twitter:title' content={listNoteSlug[0].titulo} data-react-helmet='true' />
                        <meta name='twitter:description' content={listNoteSlug[0].descripcion} data-react-helmet='true' />
                        <meta name='twitter:image' content={listNoteSlug[0].imagen} data-react-helmet='true' />
                        <meta name='twitter:card' content='summary_large_image' data-react-helmet='true' />
                    </Helmet>
                    <Header />
                    <Row className='blogContainer' justify={'space-around'}>
                        <Col xs={23} md={17}>
                            <Card className='cardBlog' title={<p className='titleNote'>AppController: una solución modular para el monitoreo y administración de almacenes</p>}>
                                <Row justify={'space-between'} align='middle'>
                                    <Col xs={24} md={5}>
                                        <Row>
                                            <p className='categoryNote'>Autor: {listNoteSlug[0].userByIdUser.name}</p>
                                        </Row>
                                        <Row>
                                            <p className='categoryNote'>Categoría: {listNoteSlug[0].categoria}</p>
                                        </Row>
                                        <Row>
                                            <p style={{ color: '#939393', marginTop: '-.6em' }}>{moment(listNoteSlug[0].fechaActualizacion).format('LLLL')}</p>
                                        </Row>
                                    </Col>
                                    <Col xs={24} md={19}>
                                        <img alt={listNoteSlug[0].titulo} className='imageNote' src={listNoteSlug[0].imagen} />
                                    </Col>
                                </Row>
                                <HTMLRenderer html={listNoteSlug[0].contenido} />
                                <br />
                                {/*   */}
                            </Card>
                            <br />
                        </Col>
                        <Col xs={23} md={6}>
                            <Card className='cardBlog' title={<p className='titleNote'>También te puede interesar:</p>}>
                                {listNotes.map((item) => (
                                    <a href={'/blog/' + item.titulo} key={item.idNota}>
                                        <Badge.Ribbon text={'Última modificación ' + moment(item.fechaActualizacion).startOf('day').fromNow()} color={'#254a74'}>
                                            <Card loading={fetchingListNotes} hoverable style={{ width: '100%' }} cover={<img alt='example' className='coverCard' src={item.imagen} />}>
                                                <Meta
                                                    title={
                                                        <Tooltip title={item.titulo} placement='topLeft'>
                                                            {item.titulo}
                                                        </Tooltip>
                                                    }
                                                    description={item.descripcion}
                                                />
                                            </Card>
                                            <br />
                                        </Badge.Ribbon>
                                    </a>
                                ))}
                                <Pagination onChange={onChangePage} current={page} simple defaultPageSize={3} total={listNotesQty} />
                            </Card>
                        </Col>
                        <Col xs={23} md={0}>
                            <br />
                        </Col>
                    </Row>
                    <Footer />
                </div>
            ) : (
                <div>
                    {fetchingListNoteSlug || fetchingGetContent ? (
                        <div class='loader-wrapper'>
                            <img class='loader-img' src={LogoSISSA} />
                        </div>
                    ) : (
                        <div>
                            <section id='notFound404' className=' align-items-center'>
                                <div id='description-container' data-aos='fade-up'>
                                    <div className='container justify-content-center'>
                                        <div className='row flex-direction-column align-items-center nf404-text pt-4'>
                                            <p className='font-white title-1'>
                                                <span> 404 </span>
                                                <span className='font-weight-light'>- Página no encontrada </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default BlogPage;
