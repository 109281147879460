import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import 'moment/locale/es';
import { Row, Col, Card, Tooltip, Input, Button, Modal, Pagination, Spin, Carousel } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import HTMLRenderer from 'react-html-renderer';
import { CardContent, IconDelete, IconEdit } from './content.style';
import ModalAddContent from '../addContent/addContent';
import ModalEditContent from '../editContent/editContent';

const { Search } = Input;
const confirm = Modal.confirm;

const Content = () => {
    const dispatch = useDispatch();

    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');

    useEffect(() => {
        dispatch({ type: 'GET_CONTENT_REQUEST', page, search });
    }, [page, search, dispatch]);

    const listContent = useSelector((state) => state.dashboardReducer.listContent);
    const fetchingListContent = useSelector((state) => state.dashboardReducer.fetchingListContent);
    const listContentQty = useSelector((state) => state.dashboardReducer.listContentQty);
    const fetchingDeleteContent = useSelector((state) => state.dashboardReducer.fetchingDeleteContent);

    const onSearchContent = (value) => {
        console.log('value', value);
        setSearch(value);
        dispatch({ type: 'GET_CONTENT_REQUEST', page, search });
    };

    const onCreateContent = () => {
        dispatch({
            type: 'SHOW_MODAL_CREATE_CONTENT',
            showModalCreateContent: true,
            page,
            search,
        });
    };
    const onEditContent = (value) => {
        console.log('value', value);
        dispatch({
            type: 'SHOW_MODAL_EDIT_CONTENT',
            showModalEditContent: true,
            selectedContent: value,
            page,
            search,
        });
    };
    const changePage = (pagination) => {
        console.log('pagination', pagination);
        setPage(pagination);
    };
    const handleDelete = (key) => {
        console.log('key', key);
        confirm({
            title: '¿Estás seguro que deseas eliminar el contenido?',
            content: '',
            okText: 'Sí',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                dispatch({
                    type: 'DELETE_CONTENT_REQUEST',
                    page,
                    search,
                    selectedContent: key,
                });
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    return (
        <Spin spinning={fetchingListContent || fetchingDeleteContent}>
            <Card className='cardCatalogos' title={<div className='header'>Administración de contenido</div>}>
                <Row type='flex' justify='space-around' gutter={[8, 8]}>
                    <Col span={23}>
                        <Search allowClear className='search' placeholder='Ingresa el título a buscar' onSearch={onSearchContent} />
                    </Col>

                    <Col span={1}>
                        <Tooltip title='Nuevo Contenido' placement='topRight'>
                            <Button className='btnPrimario' shape='circle' onClick={onCreateContent} icon={<PlusOutlined />} />
                        </Tooltip>
                    </Col>
                    <Col span={24}>
                        <Row type='flex' justify='space-around'>
                            {listContent.map((item) => (
                                <Col lg={7} md={11} xs={22} key={item.idContenido}>
                                    <CardContent>
                                        <Row type='flex' justify='space-around'>
                                            <Col span={11}>
                                                <p className='category'>{item.categoria}</p>
                                            </Col>
                                            <Col span={11}>
                                                <IconEdit onClick={() => onEditContent(item)}>
                                                    <EditOutlined />
                                                </IconEdit>
                                            </Col>
                                            <Col span={24}>
                                                {item.categoria === 'Texto' || item.categoria === 'Título' ? <HTMLRenderer html={'<h1 className="title">' + item.titulo + '</h1>'} /> : <h1 className='title'>{item.titulo}</h1>}
                                                {item.categoria === 'Texto' || item.categoria === 'Título' ? <HTMLRenderer html={'<p className="description">' + item.titulo + '</0>'} /> : <p className='description'>{item.descripcion}</p>}

                                                {item.imagenesByIdContenido ? (
                                                    <Carousel dotPosition='top' autoplay>
                                                        {item.imagenesByIdContenido.map((image) => (
                                                            <img key={image.idImagen} src={item.categoria === 'Menú' ? image.url : image.url} className='logoHeader' alt={image.descripcion} />
                                                        ))}
                                                    </Carousel>
                                                ) : (
                                                    false
                                                )}
                                                <Row type='flex' justify='space-around'>
                                                    <Col span={17}>
                                                        <p className='date'>
                                                            Último movimiento
                                                            <span> {moment(item.fechaActualizacion).startOf('day').fromNow()}</span>
                                                        </p>
                                                    </Col>
                                                    <Col span={5}>
                                                        <IconDelete onClick={() => handleDelete(item)}>
                                                            <DeleteOutlined />
                                                        </IconDelete>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </CardContent>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                    <Pagination total={listContentQty} current={page} onChange={changePage} pageSize={12} />
                </Row>
            </Card>
            <ModalAddContent />
            <ModalEditContent />
        </Spin>
    );
};

export default Content;
