import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { message } from "antd";

const Alerta = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const dispatch = useDispatch();

    const showAlerta = useSelector(
        (state) => state.dashboardReducer.showAlerta
    );
    const tipoAlerta = useSelector(
        (state) => state.dashboardReducer.tipoAlerta
    );
    const textoAlerta = useSelector(
        (state) => state.dashboardReducer.textoAlerta
    );

    useEffect(() => {
        if (showAlerta) {
            messageApi.open({
                type: tipoAlerta,
                content: textoAlerta,
            });
            dispatch({
                type: "SHOW_ALERTA_MSG",
                showAlerta: false,
                tipoAlerta: "",
                textoAlerta: "",
            });
        }
    });

    return <>{contextHolder}</>;
};

export default Alerta;
