import styled from "styled-components";

export const Container = styled.div`
    background-color: #2c4977;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    font-family: "Gotham", sans-serif;
`;

export const IconContainer = styled.div`
    svg {
        width: 20px;
        height: 20px;
        color: white;
    }
    .logoHeader {
        height: 70px;
    }
    ${({ search }) =>
        search &&
        `width: 100%; display: flex; flex-flow: row wrap; justify-content: flex-end; margin-left: -10px;`};
`;

export const ContainerMenu = styled.div`
    .ant-drawer .ant-drawer-close {
        color: white !important;
    }

    .ant-menu-inline {
        font-size: 1rem;
        background-color: #203747;
        color: white;
        .ant-menu-item-selected {
            color: #203747;
            background-color: white;
        }

        .ant-menu-item {
            &:hover {
                background-color: white;
                color: #203747;
                border-radius: 5px;
                @media only screen and (min-width: 400px) {
                    padding-left: 1em;
                    padding-right: 1em;
                }
            }
        }

        .anticon-close {
            color: white;
        }

        .ant-menu-title-content {
            &:hover {
                background-color: white;
                color: #203747;
                border-radius: 5px;
                @media only screen and (min-width: 400px) {
                    padding-left: 1em;
                    padding-right: 1em;
                }
            }
        }

        .ant-menu-submenu-title {
            &:hover {
                background-color: white;
                color: #203747;
                border-radius: 5px;
                padding: 2px;
            }
        }
    }
`;

export const ButtonContainer = styled.div`
    padding: 10px;
    color: white;
    button {
        border: 1px solid white;
    }
    svg {
        width: 30px;
        height: 30px;
    }
`;
