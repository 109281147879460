import axios from 'axios';

const endpoint = 'https://sdigital-web.herokuapp.com/';

export function getContentCall(page, offset, title) {
    return axios({
        method: 'get',
        url: endpoint + 'contenido?' + page + offset + title,
        headers: { 'Content-Type': 'application/json' },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response.data;
        });
}
