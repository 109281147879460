import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Text from '../../components/landingPage/text/text';
import Header from '../../layouts/header/header';
import Headline from '../../components/landingPage/headline/headline';
import About from '../../components/landingPage/about/about';
import Results from '../../components/landingPage/results/results';
import Cases from '../../components/landingPage/cases/cases';
import Cards from '../../components/landingPage/cards/cards';
import Team from '../../components/landingPage/team/team';
import Footer from '../../layouts/footer/footer';
import { Container } from './landingPage.style';
import { FloatButton, Tooltip } from 'antd';
import { WhatsAppOutlined } from '@ant-design/icons';
import LogoSISSA from '../../assets/logos/logoAnimation.svg';

function FadeInSection(props) {
    const [isVisible, setVisible] = React.useState(false);
    const domRef = React.useRef();
    React.useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => setVisible(entry.isIntersecting));
        });
        observer.observe(domRef.current);
    }, []);
    return (
        <div className={`fade-in-section ${isVisible ? 'is-visible' : ''}`} ref={domRef}>
            {props.children}
        </div>
    );
}

const LandingPage = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch({ type: 'GET_CONTENT_LANDING_REQUEST' });
    }, []);
    const fetchingGetContent = useSelector((state) => state.landingReducer.fetchingGetContent);

    return (
        <>
            {!fetchingGetContent ? (
                <Container>
                    {/* <section> */}
                    <Header />
                    <Headline />
                    {/* </section>
            <section> */}
                    <FadeInSection>
                        <Text />
                    </FadeInSection>
                    <FadeInSection>
                        <About />
                    </FadeInSection>
                    {/* </section>
            <section> */}
                    <FadeInSection>
                        <Results />
                    </FadeInSection>
                    {/* </section>
            <section> */}
                    <FadeInSection>
                        <Cases />
                    </FadeInSection>
                    {/* </section>
            <section> */}
                    <FadeInSection>
                        <Cards />
                    </FadeInSection>
                    {/* </section>
            <section> */}
                    <FadeInSection>
                        <Team />
                        <Footer />
                    </FadeInSection>
                    {/* </section> */}
                    <Tooltip placement='left' title='Contáctanos por WhatsApp'>
                        <FloatButton className='floatButton' shape='circle' type='primary' icon={<WhatsAppOutlined />} target='_blank' href='https://wa.me/525591856975' />
                    </Tooltip>
                </Container>
            ) : (
                <div class='loader-wrapper'>
                    <img class='loader-img' src={LogoSISSA} />
                </div>
            )}
        </>
    );
};

export default LandingPage;
