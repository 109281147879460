import React from "react";
import { Col, Row } from "antd";
import Navbar from "../../components/landingPage/navbarWeb/navbar";
import NavbarResponsive from "../../components/landingPage/navbarResponsive/navbarResponsive";

const Header = () => {
    return (
        <>
            <Row>
                <Col xs={0} sm={0} md={0} lg={24} xl={24}>
                    <Navbar />
                </Col>
                <Col xs={24} sm={24} md={24} lg={0} xl={0}>
                    <NavbarResponsive />
                </Col>
            </Row>
        </>
    );
};

export default Header;
