import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Card, Tooltip, Input, Button, Table, Switch, Modal, Avatar } from 'antd';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import ModalAddUser from '../addUser/addUser';
import ModalEditUser from '../editUser/editUser';

const { Search } = Input;
const confirm = Modal.confirm;

const TableUser = () => {
    const dispatch = useDispatch();

    const [page, setPage] = useState(1);
    const [search, setsearch] = useState('');

    useEffect(() => {
        dispatch({ type: 'OBTENER_USUARIOS_REQUEST', page, search });
    }, [page, search, dispatch]);

    const listaUsuarios = useSelector((state) => state.dashboardReducer.listaUsuarios);
    const fetchingListaUsuarios = useSelector((state) => state.dashboardReducer.fetchingListaUsuarios);
    const listaUsuariosCantidad = useSelector((state) => state.dashboardReducer.listaUsuariosCantidad);
    const fetchingEstadoUsuario = useSelector((state) => state.dashboardReducer.fetchingEstadoUsuario);

    const onSearchFolio = (value) => {
        console.log('value', value);
        setsearch(value);
    };

    const onCreateFolio = () => {
        dispatch({
            type: 'SHOW_MODAL_CREAR_USUARIO',
            showModalCrearUsuario: true,
            page,
            search,
        });
    };
    const onEditarFolio = (value) => {
        dispatch({
            type: 'SHOW_MODAL_EDIT_USER',
            showModalEditUser: true,
            selectedUser: value,
            page,
            search,
        });
    };
    const handleTableChange = (pagination) => {
        console.log('pagination', pagination);
        setPage(pagination.current);
    };
    const handleDesactivar = (key) => {
        console.log('key', key);
        confirm({
            title: '¿Estás seguro que deseas cambiar el estado del usuario "' + key.name + '"?',
            content: '',
            okText: 'Sí',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                dispatch({
                    type: 'ESTADO_USUARIO_REQUEST',
                    page,
                    search,
                    usuarioSeleccionado: key,
                });
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };
    const columns = [
        {
            key: 'name',
            dataIndex: 'name',
            render: (text, record) => <Avatar style={{ background: '#1677ff' }}>{text.split(' ')[1] ? text.split(' ')[0][0] + text.split(' ')[1][0] : text[0]}</Avatar>,
            width: '5%',
            align: 'center',
        },
        {
            title: 'Nombre completo',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Correo',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: 'Estado',
            dataIndex: 'activo',
            key: 'activo',
            render: (text, record) => <Switch checked={text} onChange={() => handleDesactivar(record)} />,
        },
        {
            title: 'Editar',
            key: '',
            align: 'center',
            render: (record) => <EditOutlined className='editarIcono' onClick={() => onEditarFolio(record)} />,
        },
    ];
    return (
        <Card className='cardCatalogos' title={<div className='header'>Administración de usuarios</div>}>
            <Row type='flex' justify='space-around' gutter={[8, 8]}>
                <Col span={23}>
                    <Search allowClear className='search' placeholder='Ingresa usuario a buscar' onSearch={onSearchFolio} />
                </Col>

                <Col span={1}>
                    <Tooltip title='Nuevo Usuario' placement='topRight'>
                        <Button className='btnPrimario' shape='circle' onClick={onCreateFolio} icon={<PlusOutlined />} />
                    </Tooltip>
                </Col>

                <Col span={24}>
                    <br />
                    <Table
                        size='small'
                        rowKey='idUser'
                        columns={columns}
                        dataSource={listaUsuarios}
                        loading={fetchingListaUsuarios || fetchingEstadoUsuario}
                        locale={{
                            emptyText: 'Sin datos',
                            filterReset: 'Limpiar',
                        }}
                        onChange={handleTableChange}
                        pagination={{
                            total: listaUsuariosCantidad,
                            simple: true,
                            current: page,
                        }}
                        scroll={{ x: 500 | true, y: 1000 }}
                    />
                </Col>
            </Row>
            <ModalAddUser />
            <ModalEditUser />
        </Card>
    );
};

export default TableUser;
