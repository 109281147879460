import { useSelector } from 'react-redux';
import Contact from '../contact/contact';
import { Col, Row, Spin } from 'antd';
import { Container } from './headline.style';
import Banner from '../banner/banner';
const Headline = () => {
    const fetchingGetContent = useSelector((state) => state.landingReducer.fetchingGetContent);
    const listContent = useSelector((state) => state.landingReducer.listContent);
    return (
        <Container>
            <Spin spinning={fetchingGetContent}>
                {listContent.length ? (
                    <Row justify='center' align='middle'>
                        <Col xs={24} lg={4}>
                            <Contact />
                        </Col>
                        <Col xs={24} lg={19}>
                            <Row justify='center' align='middle' gutter={[8, 8]}>
                                {listContent[10].imagenesByIdContenido.map((img, i) => (
                                    <Col xs={12} lg={6} key={i}>
                                        <Banner img={img} banner={listContent[10]} />
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                    </Row>
                ) : (
                    false
                )}
            </Spin>
        </Container>
    );
};

export default Headline;
