import { useSelector } from 'react-redux';
import { Row, Col, Spin, Carousel, Card } from 'antd';
import HTMLRenderer from 'react-html-renderer';
import 'react-multi-carousel/lib/styles.css';
import '../../../stylesheets/index.css';
import { Space, Title, Divider, ContainerCarousel } from './cases.style';
const { Meta } = Card;

const Successes = () => {
    const fetchingGetContent = useSelector((state) => state.landingReducer.fetchingGetContent);
    const listContent = useSelector((state) => state.landingReducer.listContent);

    return (
        <div className='animationEntry'>
            {listContent.length ? (
                <Spin spinning={fetchingGetContent}>
                    <Space />
                    <Row justify='start' align='middle'>
                        <Col lg={{ span: 6 }} xs={{ span: 3 }} md={{ span: 6 }}>
                            <Divider />
                        </Col>
                        <Col lg={{ span: 12 }} md={{ span: 12 }} xs={{ span: 18 }}>
                            <Title>
                                <HTMLRenderer html={listContent[17].titulo} />
                            </Title>
                        </Col>
                    </Row>

                    <Row justify='space-around' align='middle'>
                        <Col xs={23} lg={12}>
                            <ContainerCarousel>
                                <Carousel dotPosition='top' autoplay className='cardCarousel'>
                                    {listContent[18].imagenesByIdContenido.map((item, x) => (
                                        <Space key={x}>
                                            <a href={item.href} target='_blank' rel='noreferrer'>
                                                <Card hoverable className='cardCarousel' cover={<img alt={item.titulo} className='imageReferences' src={item.url} />}>
                                                    <Meta title={item.titulo} description={item.descripcion} />
                                                </Card>
                                            </a>
                                        </Space>
                                    ))}
                                </Carousel>
                            </ContainerCarousel>
                        </Col>
                    </Row>
                    <Space />
                </Spin>
            ) : (
                false
            )}
        </div>
    );
};

export default Successes;
