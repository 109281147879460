import styled from 'styled-components';
import backgrondImage from '../../../assets/images/web/TEXTO-SISSA_Mesa de trabajo 1.png';

export const Title = styled.div`
    background-color: #2e3a47;
    margin-top: 4em;
    font-size: 1rem;
    box-shadow: 4px 4px 4px 1px rgba(0, 0, 0, 0.4);
    font-family: 'Gotham', sans-serif;
    background-image: url(${backgrondImage});
    background-repeat: initial;

    background-size: 100%;
    background-position: center;
    @media (min-width: 1000px) {
        background-size: 100%;
    }
    p {
        padding-top: 9px;
        width: 100%;
        text-transform: uppercase;
        color: white;
        text-align: center;
        font-weight: 300;
        font-style: italic;
    }
    strong {
        font-weight: 600;
        font-style: normal;
    }
`;

export const Text = styled.div`
    font-size: 1.8rem;
    font-weight: 300;
    text-align: center;
    strong {
        color: #254a74;
        font-weight: 800;
        font-size: 2rem;
    }

    @media (min-width: 300px) and (max-width: 700px) {
        font-size: 1.35rem;
        text-align: center;
        strong {
            font-size: 1.4rem;
        }
    }
    @media (min-width: 701px) and (max-width: 991px) {
        margin-top: 2em;
        text-align: center;
        margin-top: -0.5em;
        font-size: 1.6rem;
    }
    strong {
        font-size: 1.7rem;
    }
`;

export const ShadowContainer = styled.div`
    border-radius: 50%;
    border: solid rgba(0, 0, 0, 0.1);
    border-width: 7px 7px 7px 7px;
    width: 70px;
    bottom: 0px;
    transform: translate(0, 50%);
    position: absolute;
    bottom: 0;
`;

export const IconContainer = styled.div`
    border-radius: 50%;
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #50c2e0;
    img {
        width: 50px;
    }
`;

export const Card = styled.div`
    background-color: #d7dee7;
    display: flex;
    border-radius: 5px;
    width: 100%;
    height: 7em;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-bottom: 3em;
    margin-top: 4em;
    font-family: 'Gotham', sans-serif;
    h3 {
        text-transform: uppercase;
        color: #323639;
        text-align: center;
    }

    &:hover {
        transition-duration: 0.7s;
        background-color: #50c2e0;
        h3 {
            transition-duration: 0.7s;
            color: white;
        }
        div {
            transition-duration: 0.7s;
            background-color: #d7dee7;
        }
    }
`;
