import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Spin, Input, Button, Form, Row, Col } from "antd";
import { LockOutlined, UserOutlined, MailOutlined } from "@ant-design/icons";
import ModalUI from "../../UI/modalUI/modalUI";

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

const ModalEditUser = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const showModalEditUser = useSelector((state) => state.dashboardReducer.showModalEditUser);
    const fetchingEditUser = useSelector((state) => state.dashboardReducer.fetchingEditUser);
    const page = useSelector((state) => state.dashboardReducer.page);
    const search = useSelector((state) => state.dashboardReducer.search);
    const selectedUser = useSelector((state) => state.dashboardReducer.selectedUser);

    useEffect(() => {
        form.setFieldsValue(selectedUser);
        if (!showModalEditUser) {
            form.resetFields();
        }
    }, [showModalEditUser, form, selectedUser]);

    const handleCancel = () => {
        form.resetFields();
        dispatch({
            type: "SHOW_MODAL_EDIT_USER",
            showModalEditUser: false,
            page,
            search,
        });
    };
    const onFinish = (data) => {
        form.resetFields();
        dispatch({
            type: "EDIT_USER_REQUEST",
            idUser: selectedUser.idUser,
            data,
            page,
            search,
        });
    };

    const validateMessages = {
        required: "Favor de llenar el campo!",
        types: {
            email: "No es un correo válido",
        },
    };

    return (
        <ModalUI title={"Editar usuario"} open={showModalEditUser} hideModal={handleCancel} width={900}>
            <Spin spinning={fetchingEditUser}>
                <Form {...layout} form={form} onFinish={onFinish} initialValues={selectedUser} validateMessages={validateMessages}>
                    <Row justify="space-around">
                        <Col xs={24} lg={11}>
                            <Form.Item
                                label="Nombre completo"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input prefix={<UserOutlined className="iconoPrefix" />} placeholder="Nombre completo" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={11}>
                            <Form.Item
                                label="Correo"
                                name="username"
                                rules={[
                                    {
                                        type: "email",
                                        required: true,
                                    },
                                ]}
                            >
                                <Input prefix={<MailOutlined className="iconoPrefix" />} placeholder="Correo" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={11}>
                            <Form.Item
                                label="Contraseña"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input.Password prefix={<LockOutlined className="iconoPrefix" />} placeholder="Contraseña" />
                            </Form.Item>
                        </Col>
                        <Col xs={22} lg={11} className="marginBoton">
                            <br />
                            <Button className="btnPrimario" htmlType="submit">
                                Editar Usuario
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </ModalUI>
    );
};

export default ModalEditUser;
