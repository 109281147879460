import styled from 'styled-components';

export const Wrap = styled.div`
    position: relative;
    img {
        width: 100%;
        margin-top: 3px;
        border-radius: 4em;
        box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);
        filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.3));
        @media (max-width: 1000px) {
            margin-top: 0px;
        }
        animation: glow 1s ease-in-out infinite alternate;
        -webkit-animation: focus-in-expand 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        animation: focus-in-expand 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    }
    @-webkit-keyframes focus-in-expand {
        0% {
            letter-spacing: -0.5em;
            -webkit-filter: blur(12px);
            filter: blur(12px);
            opacity: 0;
        }
        100% {
            -webkit-filter: blur(0px);
            filter: blur(0px);
            opacity: 1;
        }
    }
    @keyframes focus-in-expand {
        0% {
            letter-spacing: -0.5em;
            -webkit-filter: blur(12px);
            filter: blur(12px);
            opacity: 0;
        }
        100% {
            -webkit-filter: blur(0px);
            filter: blur(0px);
            opacity: 1;
        }
    }
`;
