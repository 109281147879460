import styled from 'styled-components';
import backgrondImage from '../../assets/logos/MINI-BANNER-CMMI.png';

export const Background = styled.div`
    background-image: url(${backgrondImage});
    background-repeat: initial;
    width: 100%;
    background-position: center;

    .logoFooter {
        padding-left: 2em;
        width: 100%;
    }
    .shadowCMMI {
        -webkit-box-shadow: 17px 17px 18px -11px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 17px 17px 18px -11px rgba(0, 0, 0, 0.75);
        box-shadow: 17px 17px 18px -11px rgba(0, 0, 0, 0.75);
        width: 100%;
    }
    .shadowBtn {
        width: 100%;
    }
`;

export const FooterLayour = styled.div`
    background-color: #254a74;
    width: 100%;
    color: white;
    .copyrigth,
    .privacy {
        font-family: 'Gotham Light', sans-serif;
        font-size: 1.2rem;
        @media (min-width: 300px) and (max-width: 700px) {
            text-align: center !important;
        }
    }
    .copyrigth {
        text-align: left;
    }
    .privacy {
        text-align: right;
    }
`;

export const IconContainer = styled.div`
    p {
        text-align: center;
        font-size: 1.5em;
        color: white;
        font-family: 'Times New Roman', sans-serif;
    }
    strong {
        font-size: 1.4em;
        color: #215aaf;
        font-family: 'Gotham Black', sans-serif;
        -webkit-text-stroke: 1px #4de1fe;
        text-shadow: 0px 0px 8px #4de1fe;
    }
`;
