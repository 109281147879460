import styled from 'styled-components';

export const Container = styled.div`
    section {
        scroll-snap-align: start;
        scroll-snap-stop: always;

        align-items: center;
        justify-content: center;
    }
    .fade-in-section {
        opacity: 0;
        transform: translateY(20vh);
        visibility: hidden;
        transition: opacity 1200ms ease-out, transform 600ms ease-out, visibility 1200ms ease-out;
        will-change: opacity, transform, visibility;
    }
    .fade-in-section.is-visible {
        opacity: 1;
        transform: none;
        visibility: visible;
    }
    .floatButton {
        margin-right: 1em;
        transition: 0.5s;
        transform: scale(1.5);
    }
    .floatButton:hover {
        transform: scale(2);
    }
`;
