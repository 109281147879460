import React from "react";
import { Col, Row } from "antd";
import Navbar from "../../components/administrador/navbar/navbarWeb/navbar";
import NavbarResponsive from "../../components/administrador/navbar/navbarResponsive/navbarResponsive";

const Header = () => {
    return (
        <>
            <Row>
                <Col xs={0} lg={24}>
                    <Navbar />
                </Col>
                <Col xs={24} lg={0}>
                    <NavbarResponsive />
                </Col>
            </Row>
        </>
    );
};

export default Header;
