import styled from 'styled-components';

export const IconContainer = styled.div`
    svg {
        width: 20px;
        height: 20px;
    }
    .imgIcon {
        width: 100%;
    }
`;

export const ContainerLogo = styled.div`
    .imgLogo {
        width: 100%;
    }
`;
