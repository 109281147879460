import { Col, Row } from "antd";
import { Wrap } from "./banner.style";

const Banner = ({ img, banner }) => {
    return (
        <Row justify="center" align="middle">
            <Col xs={{ span: 22 }} lg={{ span: 23 }}>
                <Wrap>
                    <img src={img.url} alt={banner.descripcion} />
                </Wrap>
            </Col>
            <Col xs={24} sm={24} md={24} lg={0} xl={0}>
                <br/>
            </Col>
        </Row>
    );
};

export default Banner;
