import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { Col, Row, Drawer, Menu } from 'antd';
import './style.css';
import { MenuOutlined, SearchOutlined } from '@ant-design/icons';
import { Container, ContainerMenu, ButtonContainer, IconContainer } from './navbarResponsive.style';
import LogoSISSA from '../../../assets/logos/logotipo_SISSA_DIGITAL_blanco_completo.svg';
const NavbarResponsive = () => {
    const [showDrawer, setShowDrawer] = useState(false);
    const params = useParams();
    function getItem(label, key, icon, children) {
        return {
            key,
            icon,
            children,
            label: (
                <a href={'#' + key} rel='noopener noreferrer'>
                    {label}
                </a>
            ),
        };
    }
    const items = [getItem('Nosotros', 'nosotros'), getItem('Productos', 'productos'), getItem('Servicios', 'servicios'), getItem('Noticias', 'noticias')];

    return (
        <>
            <Container>
                <Row justify='start' align='middle'>
                    {!params.slug ? (
                        <Col xs={2} md={1} offset={1}>
                            <Drawer
                                placement={'left'}
                                closable={true}
                                onClose={() => setShowDrawer(false)}
                                open={showDrawer}
                                key={'left'}
                                style={{
                                    backgroundColor: '#254a74',
                                    color: 'white',
                                }}
                            >
                                <ContainerMenu>
                                    <Menu mode='inline' onClick={() => setShowDrawer(false)} style={{ width: '100%' }} defaultOpenKeys={['productos']} items={items} />
                                </ContainerMenu>
                            </Drawer>
                            <ButtonContainer onClick={() => setShowDrawer(true)}>
                                <MenuOutlined />
                            </ButtonContainer>
                        </Col>
                    ) : (
                        false
                    )}
                    <Col xs={10} md={4} offset={1}>
                        <IconContainer>
                            <a href='/'>
                                <img src={LogoSISSA} className='logoHeader' alt='Logo SISSA' />
                            </a>
                        </IconContainer>
                    </Col>
                    {/* <Col xs={9} md={16}>
                        <IconContainer search>
                            <SearchOutlined />
                        </IconContainer>
                    </Col> */}
                </Row>
            </Container>
        </>
    );
};

export default NavbarResponsive;
