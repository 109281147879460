import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { Col, Row, Alert } from 'antd';
import ReactVivus from 'react-vivus';

import logoDigital from '../../assets/logos/logotipo_SISSA_DIGITAL.svg';
import fondoLogin from '../../assets/logos/fondoLogin.svg';
import LoginFormulario from '../../components/administrador/login/loginFormulario';

const LoginContainer = () => {
    const isAuthenticated = useSelector((state) => state.loginReducer.isAuthenticated);
    const errorLogin = useSelector((state) => state.loginReducer.errorLogin);
    const errorMensaje = useSelector((state) => state.loginReducer.errorMensaje);

    if (isAuthenticated) {
        return <Navigate to='/dashboard' />;
    }
    function ErrorLoginAlert() {
        if (!errorLogin) {
            return null;
        }
        return <Alert message={errorMensaje} type='error' showIcon />;
    }

    return (
        <Row justify='space-between'>
            <Col xs={0} md={12} className='fondoLogin'>
                <div className='fondo-box'>
                    {/* <img
                        alt="fondoDigital"
                        src={fondoLogin}
                        className="fondo"
                    /> */}
                    <ReactVivus
                        id='fondoLogin'
                        option={{
                            file: '/fondoLogin.svg',
                            animTimingFunction: 'EASE',
                            type: 'delayed',
                            start: 'autostart',
                        }}
                        className='fondo'
                    />
                </div>
            </Col>
            <Col xs={24} md={12}>
                <Row justify='space-around'>
                    <Col xs={22} md={16}>
                        <img alt='logo SISSA' src={logoDigital} className='login-logo animacionLogo' />
                    </Col>
                </Row>
                <Row justify='space-around'>
                    <Col xs={22} md={16}>
                        <h1 style={{ textAlign: 'center' }}>BIENVENIDO A TU ADMINISTRADOR</h1>
                    </Col>
                </Row>
                <br />
                <Row justify='space-around'>
                    <Col xs={22} md={16}>
                        <ErrorLoginAlert />
                    </Col>
                </Row>

                <Row justify='space-around'>
                    <Col xs={22} md={16}>
                        <LoginFormulario />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};
export default LoginContainer;
