import styled from 'styled-components';

export const ContainerDivider = styled.div`
    .ant-divider-horizontal {
        background-color: #2e3a47;
    }
    .ant-divider-vertical {
        background-color: #191e25;
        height: 140px;
    }
`;

export const Title = styled.div`
    font-size: 1.8rem;
    font-weight: 300;
    text-transform: uppercase;
    strong {
        color: #254a74;
        font-weight: 800;
        font-size: 2rem;
    }

    @media (min-width: 300px) and (max-width: 700px) {
        font-size: 1.35rem;
        text-align: center;
        strong {
            font-size: 1.4rem;
        }
    }
    @media (min-width: 701px) and (max-width: 991px) {
        margin-top: 2em;
        text-align: center;
        margin-top: -0.5em;
        font-size: 1.6rem;
    }
    strong {
        font-size: 1.7rem;
    }
`;

export const Subtitle = styled.div`
    font-family: 'Gotham Light', sans-serif;
    font-size: 1.2rem;
    @media (min-width: 300px) and (max-width: 700px) {
        margin-top: 1.5em;
        font-size: 1rem;
        font-weight: 200;
        text-align: center;
    }
    @media (min-width: 701px) and (max-width: 991px) {
        margin-top: 1.5em;
        text-align: center;
        margin-bottom: 1em;
        font-size: 1.3rem;
    }
`;
