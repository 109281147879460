import { takeLatest, call, put } from 'redux-saga/effects';
import * as dashboardActions from '../actions/dashboardActions';
import { listaUsuariosCall, crearUsuarioCall, editUsersCall, estadoUsuariosCall, getContentCall, createContentCall, updateContentCall, deleteContentCall, getNotesCall, createNotesCall, updateNotesCall, deleteNotesCall, createImageByContentCall, editImageByContentCall } from '../services/dashboardServices';

//********************** Lista de usuarios para la tabla en administración de usuarios *****************************
function* listaUsuariosGet(action) {
    try {
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        const search = action.search ? 'name=' + action.search + '&' : '';
        const response = yield call(listaUsuariosCall, token, page, search);
        if (response.status === 200) {
            let listaUsuarios = response.data.data;
            let listaUsuariosCantidad = response.data.count;

            yield put({
                type: dashboardActions.OBTENER_USUARIOS_SUCCESS,
                listaUsuarios,
                listaUsuariosCantidad,
            });
        } else {
            yield put({ type: dashboardActions.OBTENER_USUARIOS_FAILURE });
            yield put({
                type: dashboardActions.SHOW_ALERTA_MSG,
                showAlerta: true,
                tipoAlerta: 'error',
                textoAlerta: response.message,
            });
        }
    } catch (error) {
        yield put({ type: dashboardActions.OBTENER_USUARIOS_FAILURE });
        yield put({
            type: dashboardActions.SHOW_ALERTA_MSG,
            showAlerta: true,
            tipoAlerta: 'error',
            textoAlerta: 'Hubo un error, favor de contactar al administrador',
        });
    }
}
export function* listaUsuariosGetSaga() {
    yield takeLatest(dashboardActions.OBTENER_USUARIOS_REQUEST, listaUsuariosGet);
}

//********************** Crear usuario *****************************
function* crearUsuario(action) {
    try {
        const token = sessionStorage.getItem('token');
        const data = {
            name: action.formulario.name,
            username: action.formulario.username,
            password: action.formulario.password,
        };
        const response = yield call(crearUsuarioCall, token, data);
        if (response.status === 201) {
            yield put({ type: dashboardActions.CREAR_USUARIO_SUCCESS });
            yield put({
                type: dashboardActions.OBTENER_USUARIOS_REQUEST,
                page: action.page,
                search: action.search,
            });
            yield put({
                type: dashboardActions.SHOW_ALERTA_MSG,
                showAlerta: true,
                tipoAlerta: 'success',
                textoAlerta: 'Se creó el usuario ' + action.formulario.name + ' de forma correcta',
            });
        } else {
            yield put({ type: dashboardActions.CREAR_USUARIO_FAILURE });
            yield put({
                type: dashboardActions.SHOW_ALERTA_MSG,
                showAlerta: true,
                tipoAlerta: 'error',
                textoAlerta: response.message,
            });
        }
    } catch (error) {
        yield put({ type: dashboardActions.CREAR_USUARIO_FAILURE });
        yield put({
            type: dashboardActions.SHOW_ALERTA_MSG,
            showAlerta: true,
            tipoAlerta: 'error',
            textoAlerta: 'Hubo un error, favor de contactar al administrador',
        });
    }
}
export function* crearUsuarioSaga() {
    yield takeLatest(dashboardActions.CREAR_USUARIO_REQUEST, crearUsuario);
}

//********************** Edit user *****************************
function* editUser(action) {
    try {
        const token = sessionStorage.getItem('token');
        const idUser = action.idUser;
        const form = action.data;
        const data = {
            name: form.name,
            username: form.username,
            password: form.password,
        };
        const response = yield call(editUsersCall, token, idUser, data);
        if (response.status === 200) {
            yield put({ type: dashboardActions.EDIT_USER_SUCCESS });
            yield put({
                type: dashboardActions.OBTENER_USUARIOS_REQUEST,
                page: action.page,
                search: action.search,
            });
            yield put({
                type: dashboardActions.SHOW_ALERTA_MSG,
                showAlerta: true,
                tipoAlerta: 'success',
                textoAlerta: 'Se editó el usuario ' + form.name + ' de forma correcta',
            });
            yield put({
                type: dashboardActions.SHOW_MODAL_EDIT_USER,
                showModalEditUser: false,
            });
        } else {
            yield put({ type: dashboardActions.EDIT_USER_FAILURE });
            yield put({
                type: dashboardActions.SHOW_ALERTA_MSG,
                showAlerta: true,
                tipoAlerta: 'error',
                textoAlerta: response.message,
            });
        }
    } catch (error) {
        yield put({ type: dashboardActions.EDIT_USER_FAILURE });
        yield put({
            type: dashboardActions.SHOW_ALERTA_MSG,
            showAlerta: true,
            tipoAlerta: 'error',
            textoAlerta: 'Hubo un error, favor de contactar al administrador',
        });
    }
}
export function* editUserSaga() {
    yield takeLatest(dashboardActions.EDIT_USER_REQUEST, editUser);
}

//********************** Desactivar usuario por id *****************************
function* cambiarEstadoUsuario(action) {
    try {
        const token = sessionStorage.getItem('token');
        const response = yield call(estadoUsuariosCall, token, action.usuarioSeleccionado.idUser);
        if (response.status === 200) {
            yield put({ type: dashboardActions.ESTADO_USUARIO_SUCCESS });
            yield put({
                type: dashboardActions.OBTENER_USUARIOS_REQUEST,
                page: action.page,
                search: action.search,
            });
            yield put({
                type: dashboardActions.SHOW_ALERTA_MSG,
                showAlerta: true,
                tipoAlerta: 'success',
                textoAlerta: 'Se cambió el estado del usuario ' + action.usuarioSeleccionado.name + ' de forma correcta',
            });
        } else {
            yield put({ type: dashboardActions.ESTADO_USUARIO_FAILURE });
            yield put({
                type: dashboardActions.SHOW_ALERTA_MSG,
                showAlerta: true,
                tipoAlerta: 'error',
                textoAlerta: response.message,
            });
        }
    } catch (error) {
        yield put({ type: dashboardActions.ESTADO_USUARIO_FAILURE });
        yield put({
            type: dashboardActions.SHOW_ALERTA_MSG,
            showAlerta: true,
            tipoAlerta: 'error',
            textoAlerta: 'Hubo un error, favor de contactar al administrador',
        });
    }
}
export function* cambiarEstadoUsuarioSaga() {
    yield takeLatest(dashboardActions.ESTADO_USUARIO_REQUEST, cambiarEstadoUsuario);
}

//********************** Get content *****************************
function* getContent(action) {
    try {
        console.log('action :>> ', action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        const title = action.search ? 'title=' + action.search + '&' : '';
        const response = yield call(getContentCall, token, title, page);
        console.log('response', response);
        if (response.status === 200) {
            const listContent = response.data.data;
            const listContentQty = response.data.count;
            yield put({ type: dashboardActions.GET_CONTENT_SUCCESS, listContent, listContentQty });
            yield put({ type: dashboardActions.OBTENER_USUARIOS_REQUEST, page: action.page, search: action.search });
        } else {
            yield put({ type: dashboardActions.GET_CONTENT_FAILURE });
            yield put({ type: dashboardActions.SHOW_ALERTA_MSG, showAlerta: true, tipoAlerta: 'error', textoAlerta: response.message });
        }
    } catch (error) {
        yield put({ type: dashboardActions.GET_CONTENT_FAILURE });
        yield put({ type: dashboardActions.SHOW_ALERTA_MSG, showAlerta: true, tipoAlerta: 'error', textoAlerta: 'Hubo un error, favor de contactar al administrador' });
    }
}
export function* getContentSaga() {
    yield takeLatest(dashboardActions.GET_CONTENT_REQUEST, getContent);
}

//********************** Create Content *****************************
function* createContent(action) {
    try {
        console.log('action', action);
        const token = sessionStorage.getItem('token');
        const form = action.data;
        const data = {
            categoria: form.category,
            posicion: form.posicion,
            titulo: form.htmlEditor ? form.htmlEditor : form.titulo,
            descripcion: form.htmlEditor ? form.htmlEditor : form.descripcion,
        };
        console.log('data', data, 'form', form);
        const response = yield call(createContentCall, token, data);
        console.log('response', response);
        if (response.status === 200) {
            yield put({ type: dashboardActions.CREATE_CONTENT_SUCCESS });
            if (form.imagen) {
                let fileList = form.imagen.fileList;

                fileList.forEach((element) => {
                    if (form[element.name]) {
                        element.descripcion = form[element.name].descripcion;
                        element.titulo = form[element.name].titulo;
                        element.href = form[element.name].href;
                    }
                });

                form.imagen.fileList = fileList;

                yield put({ type: dashboardActions.CREATE_IMAGE_BY_CONTENT_REQUEST, page: action.page, search: action.search, form, idContenido: response.data.info.idContenido });
            } else {
                yield put({ type: dashboardActions.GET_CONTENT_REQUEST, page: action.page, search: action.search });
                yield put({ type: dashboardActions.SHOW_ALERTA_MSG, showAlerta: true, tipoAlerta: 'success', textoAlerta: 'Se creó el contenido ' + form.titulo + ' de forma correcta' });
                yield put({ type: dashboardActions.SHOW_MODAL_CREATE_CONTENT, showModalCreateContent: false });
                yield put({ type: dashboardActions.CLEAN_FORM, cleanForm: 'cleanAddContent' });
            }
        } else {
            yield put({ type: dashboardActions.CREATE_CONTENT_FAILURE });
            yield put({ type: dashboardActions.SHOW_ALERTA_MSG, showAlerta: true, tipoAlerta: 'error', textoAlerta: response.message });
        }
    } catch (error) {
        yield put({ type: dashboardActions.CREATE_CONTENT_FAILURE });
        yield put({ type: dashboardActions.SHOW_ALERTA_MSG, showAlerta: true, tipoAlerta: 'error', textoAlerta: 'Hubo un error, favor de contactar al administrador' });
    }
}
export function* createContentSaga() {
    yield takeLatest(dashboardActions.CREATE_CONTENT_REQUEST, createContent);
}

//********************** Update content *****************************
function* updateContent(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idContenido = action.selectedContent.idContenido;
        const form = action.data;
        const data = {
            categoria: action.selectedContent.categoria,
            posicion: action.selectedContent.posicion,
            titulo: form.htmlEditor ? form.htmlEditor : form.titulo,
            descripcion: form.htmlEditor ? form.htmlEditor : form.descripcion,
        };
        console.log('data', data, 'idContenido', idContenido);
        const response = yield call(updateContentCall, token, idContenido, data);
        console.log('response', response);
        if (response.status === 200) {
            yield put({ type: dashboardActions.UPDATE_CONTENT_SUCCESS });
            if (form.imagen) {
                let fileList = form.imagen.fileList;

                fileList.forEach((element) => {
                    if (form[element.name]) {
                        element.descripcion = form[element.name].descripcion;
                        element.titulo = form[element.name].titulo;
                        element.href = form[element.name].href;
                    }
                });

                form.imagen.fileList = fileList;

                yield put({ type: dashboardActions.EDIT_IMAGE_BY_CONTENT_REQUEST, page: action.page, search: action.search, form, selectedContent: action.selectedContent });
            } else {
                yield put({ type: dashboardActions.GET_CONTENT_REQUEST, page: action.page, search: action.search });
                yield put({ type: dashboardActions.SHOW_ALERTA_MSG, showAlerta: true, tipoAlerta: 'success', textoAlerta: 'Se editó el contenido ' + form.titulo + ' de forma correcta' });
                yield put({ type: dashboardActions.SHOW_MODAL_EDIT_CONTENT, showModalEditContent: false });
            }
        } else {
            yield put({ type: dashboardActions.UPDATE_CONTENT_FAILURE });
            yield put({ type: dashboardActions.SHOW_ALERTA_MSG, showAlerta: true, tipoAlerta: 'error', textoAlerta: response.message });
        }
    } catch (error) {
        yield put({ type: dashboardActions.UPDATE_CONTENT_FAILURE });
        yield put({ type: dashboardActions.SHOW_ALERTA_MSG, showAlerta: true, tipoAlerta: 'error', textoAlerta: 'Hubo un error, favor de contactar al administrador' });
    }
}
export function* updateContentSaga() {
    yield takeLatest(dashboardActions.UPDATE_CONTENT_REQUEST, updateContent);
}

//********************** Delete content *****************************
function* deleteContent(action) {
    try {
        const token = sessionStorage.getItem('token');
        const selectedContent = action.selectedContent;
        const idContenido = selectedContent.idContenido;
        console.log('idContenido', idContenido, 'selectedContent', selectedContent);

        const response = yield call(deleteContentCall, token, idContenido);
        console.log('response', response);
        if (response.status === 200) {
            yield put({ type: dashboardActions.DELETE_CONTENT_SUCCESS });
            yield put({
                type: dashboardActions.GET_CONTENT_REQUEST,
                page: action.page,
                search: action.search,
            });
            yield put({
                type: dashboardActions.SHOW_ALERTA_MSG,
                showAlerta: true,
                tipoAlerta: 'success',
                textoAlerta: 'Se eliminó el contenido de forma correcta',
            });
        } else {
            yield put({ type: dashboardActions.DELETE_CONTENT_FAILURE });
            yield put({
                type: dashboardActions.SHOW_ALERTA_MSG,
                showAlerta: true,
                tipoAlerta: 'error',
                textoAlerta: response.message,
            });
        }
    } catch (error) {
        yield put({ type: dashboardActions.DELETE_CONTENT_FAILURE });
        yield put({
            type: dashboardActions.SHOW_ALERTA_MSG,
            showAlerta: true,
            tipoAlerta: 'error',
            textoAlerta: 'Hubo un error, favor de contactar al administrador',
        });
    }
}
export function* deleteContentSaga() {
    yield takeLatest(dashboardActions.DELETE_CONTENT_REQUEST, deleteContent);
}

//********************** Create images *****************************
function* createImageByContent(action) {
    try {
        console.log('action', action);
        const token = sessionStorage.getItem('token');
        const data = [];
        if (action.form.category === 'Menú' || action.form.category === 'Imagen' || action.form.category === 'Carrousel') {
            for (let i in action.form.imagen.fileList) {
                const image = {
                    idContenido: action.idContenido,
                    url: action.form.imagen.fileList[i].base64,
                    href: action.form.imagen.fileList[i].href ? action.form.imagen.fileList[i].href : action.form.href ? action.form.href : '',
                    titulo: action.form.imagen.fileList[i].titulo ? action.form.imagen.fileList[i].titulo : action.form.titulo ? action.form.titulo : '',
                    descripcion: action.form.imagen.fileList[i].descripcion ? action.form.imagen.fileList[i].descripcion : action.form.descripcion ? action.form.descripcion : '',
                };
                data.push(image);
            }
        }
        console.log('data', data);
        const response = yield call(createImageByContentCall, token, data);
        console.log('response', response);
        if (response.status === 200) {
            yield put({ type: dashboardActions.CREATE_IMAGE_BY_CONTENT_SUCCESS });
            yield put({ type: dashboardActions.GET_CONTENT_REQUEST, page: action.page, search: action.search });
            yield put({ type: dashboardActions.SHOW_ALERTA_MSG, showAlerta: true, tipoAlerta: 'success', textoAlerta: 'Se creó el contenido de forma correcta' });
            yield put({ type: dashboardActions.SHOW_MODAL_CREATE_CONTENT, showModalCreateContent: false });
            yield put({ type: dashboardActions.CLEAN_FORM, cleanForm: 'cleanAddContent' });
        } else {
            yield put({ type: dashboardActions.CREATE_IMAGE_BY_CONTENT_FAILURE });
            yield put({ type: dashboardActions.SHOW_ALERTA_MSG, showAlerta: true, tipoAlerta: 'error', textoAlerta: response.message });
        }
    } catch (error) {
        yield put({ type: dashboardActions.CREATE_IMAGE_BY_CONTENT_FAILURE });
        yield put({ type: dashboardActions.SHOW_ALERTA_MSG, showAlerta: true, tipoAlerta: 'error', textoAlerta: 'Hubo un error, favor de contactar al administrador' });
    }
}
export function* createImageByContentSaga() {
    yield takeLatest(dashboardActions.CREATE_IMAGE_BY_CONTENT_REQUEST, createImageByContent);
}

//********************** Edit images *****************************
function* editImageByContent(action) {
    try {
        console.log('action', action);
        const token = sessionStorage.getItem('token');
        const data = [];
        const idImage = [];
        if (action.selectedContent.categoria === 'Menú' || action.selectedContent.categoria === 'Imagen' || action.selectedContent.categoria === 'Carrousel') {
            for (let i in action.selectedContent.imagenesByIdContenido) {
                idImage.push(action.selectedContent.imagenesByIdContenido[i].idImagen);
            }
            for (let i in action.form.imagen.fileList) {
                const image = {
                    idContenido: action.selectedContent.idContenido,
                    url: action.form.imagen.fileList[i].base64,
                    href: action.form.imagen.fileList[i].href ? action.form.imagen.fileList[i].href : action.form.href ? action.form.href : '',
                    titulo: action.form.imagen.fileList[i].titulo ? action.form.imagen.fileList[i].titulo : action.form.titulo ? action.form.titulo : '',
                    descripcion: action.form.imagen.fileList[i].descripcion ? action.form.imagen.fileList[i].descripcion : action.form.descripcion ? action.form.descripcion : '',
                };
                data.push(image);
            }
        }
        console.log('data', data, idImage);
        const response = yield call(editImageByContentCall, token, data, idImage);
        console.log('response', response);
        if (response.status === 200) {
            yield put({ type: dashboardActions.EDIT_IMAGE_BY_CONTENT_SUCCESS });
            yield put({ type: dashboardActions.GET_CONTENT_REQUEST, page: action.page, search: action.search });
            yield put({ type: dashboardActions.SHOW_ALERTA_MSG, showAlerta: true, tipoAlerta: 'success', textoAlerta: 'Se editó el contenido de forma correcta' });
            yield put({ type: dashboardActions.SHOW_MODAL_EDIT_CONTENT, showModalEditContent: false });
            yield put({ type: dashboardActions.CLEAN_FORM, cleanForm: 'cleanEditContent' });
        } else {
            yield put({ type: dashboardActions.EDIT_IMAGE_BY_CONTENT_FAILURE });
            yield put({ type: dashboardActions.SHOW_ALERTA_MSG, showAlerta: true, tipoAlerta: 'error', textoAlerta: response.message });
        }
    } catch (error) {
        yield put({ type: dashboardActions.EDIT_IMAGE_BY_CONTENT_FAILURE });
        yield put({ type: dashboardActions.SHOW_ALERTA_MSG, showAlerta: true, tipoAlerta: 'error', textoAlerta: 'Hubo un error, favor de contactar al administrador' });
    }
}
export function* editImageByContentSaga() {
    yield takeLatest(dashboardActions.EDIT_IMAGE_BY_CONTENT_REQUEST, editImageByContent);
}

//********************** Get notes *****************************
function* getNotes(action) {
    try {
        const page = action.page - 1;
        const offset = '&offset=' + action.offset;

        const response = yield call(getNotesCall, '', page, offset);
        console.log('response', response);
        if (response.status === 200) {
            const listNotes = response.data.data;
            const listNotesQty = response.data.count;
            yield put({ type: dashboardActions.GET_NOTES_SUCCESS, listNotes, listNotesQty });
        } else {
            yield put({ type: dashboardActions.GET_NOTES_FAILURE });
            yield put({ type: dashboardActions.SHOW_ALERTA_MSG, showAlerta: true, tipoAlerta: 'error', textoAlerta: response.message });
        }
    } catch (error) {
        yield put({ type: dashboardActions.GET_NOTES_FAILURE });
        yield put({ type: dashboardActions.SHOW_ALERTA_MSG, showAlerta: true, tipoAlerta: 'error', textoAlerta: 'Hubo un error, favor de contactar al administrador' });
    }
}
export function* getNotesSaga() {
    yield takeLatest(dashboardActions.GET_NOTES_REQUEST, getNotes);
}

//********************** Get note SLUG *****************************
function* getNoteSlug(action) {
    try {
        const param = action.param ? '&titulo=' + action.param : '';
        const page = action.page - 1;
        const offset = '&offset=' + action.offset;

        const response = yield call(getNotesCall, param, page, offset);
        console.log('response', response);
        if (response.status === 200) {
            const listNoteSlug = response.data.data;
            yield put({ type: dashboardActions.GET_NOTE_SLUG_SUCCESS, listNoteSlug });
        } else {
            yield put({ type: dashboardActions.GET_NOTE_SLUG_FAILURE });
            yield put({ type: dashboardActions.SHOW_ALERTA_MSG, showAlerta: true, tipoAlerta: 'error', textoAlerta: response.message });
        }
    } catch (error) {
        yield put({ type: dashboardActions.GET_NOTE_SLUG_FAILURE });
        yield put({ type: dashboardActions.SHOW_ALERTA_MSG, showAlerta: true, tipoAlerta: 'error', textoAlerta: 'Hubo un error, favor de contactar al administrador' });
    }
}
export function* getNoteSlugSaga() {
    yield takeLatest(dashboardActions.GET_NOTE_SLUG_REQUEST, getNoteSlug);
}

//********************** Create Notes *****************************
function* createNotes(action) {
    try {
        console.log('action', action);
        const token = sessionStorage.getItem('token');
        const form = action.data;
        const data = {
            idContenido: 42,
            categoria: 'Nota',
            titulo: form.titulo,
            descripcion: form.descripcion,
            imagen: form.imagen.file.base64,
            contenido: form.htmlEditor,
        };
        console.log('data', data);
        const response = yield call(createNotesCall, token, data);
        console.log('response', response);
        if (response.status === 200) {
            yield put({ type: dashboardActions.CREATE_NOTES_SUCCESS });
            yield put({ type: dashboardActions.GET_NOTES_REQUEST, page: action.page, search: action.search });
            yield put({ type: dashboardActions.SHOW_ALERTA_MSG, showAlerta: true, tipoAlerta: 'success', textoAlerta: 'Se creó la nota ' + form.titulo + ' de forma correcta' });
            yield put({ type: dashboardActions.CLEAN_FORM, cleanForm: 'addNote' });
        } else {
            yield put({ type: dashboardActions.CREATE_NOTES_FAILURE });
            yield put({ type: dashboardActions.SHOW_ALERTA_MSG, showAlerta: true, tipoAlerta: 'error', textoAlerta: response.message });
        }
    } catch (error) {
        yield put({ type: dashboardActions.CREATE_NOTES_FAILURE });
        yield put({ type: dashboardActions.SHOW_ALERTA_MSG, showAlerta: true, tipoAlerta: 'error', textoAlerta: 'Hubo un error, favor de contactar al administrador' });
    }
}
export function* createNotesSaga() {
    yield takeLatest(dashboardActions.CREATE_NOTES_REQUEST, createNotes);
}

//********************** Update notes *****************************
function* updateNotes(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idNota = action.selectedNotes.idNota;
        const form = action.data;
        console.log('form.imagen.file :>> ', form.imagen.file);
        const data = {
            categoria: form.categoria,
            titulo: form.titulo,
            descripcion: form.descripcion,
            subtitulo: '',
            imagen: form.imagen.file ? form.imagen.file.base64 : '',
            contenido: form.contenido,
        };
        console.log('data', data, 'idNota', idNota);
        const response = yield call(updateNotesCall, token, idNota, data);
        console.log('response', response);
        if (response.status === 200) {
            yield put({ type: dashboardActions.UPDATE_NOTES_SUCCESS });
            yield put({ type: dashboardActions.GET_NOTES_REQUEST, page: action.page, search: action.search });
            yield put({ type: dashboardActions.SHOW_ALERTA_MSG, showAlerta: true, tipoAlerta: 'success', textoAlerta: 'Se editó el contenido ' + form.titulo + ' de forma correcta' });
            yield put({ type: dashboardActions.CLEAN_FORM, cleanForm: 'editNote' });
        } else {
            yield put({ type: dashboardActions.UPDATE_NOTES_FAILURE });
            yield put({ type: dashboardActions.SHOW_ALERTA_MSG, showAlerta: true, tipoAlerta: 'error', textoAlerta: response.message });
        }
    } catch (error) {
        yield put({ type: dashboardActions.UPDATE_NOTES_FAILURE });
        yield put({ type: dashboardActions.SHOW_ALERTA_MSG, showAlerta: true, tipoAlerta: 'error', textoAlerta: 'Hubo un error, favor de contactar al administrador' });
    }
}
export function* updateNotesSaga() {
    yield takeLatest(dashboardActions.UPDATE_NOTES_REQUEST, updateNotes);
}

//********************** Delete notes *****************************
function* deleteNotes(action) {
    try {
        const token = sessionStorage.getItem('token');
        const selectedNotes = action.selectedNotes;
        const idNota = selectedNotes.idNota;
        console.log('idNota', idNota, 'selectedNotes', selectedNotes);

        const response = yield call(deleteNotesCall, token, idNota);
        console.log('response', response);
        if (response.status === 200) {
            yield put({ type: dashboardActions.DELETE_NOTES_SUCCESS });
            yield put({ type: dashboardActions.GET_NOTES_REQUEST, page: action.page, search: action.search });
            yield put({ ype: dashboardActions.SHOW_ALERTA_MSG, showAlerta: true, tipoAlerta: 'success', textoAlerta: 'Se eliminó el contenido de "' + selectedNotes.titulo + '" de forma correcta' });
        } else {
            yield put({ type: dashboardActions.DELETE_NOTES_FAILURE });
            yield put({ type: dashboardActions.SHOW_ALERTA_MSG, showAlerta: true, tipoAlerta: 'error', textoAlerta: response.message });
        }
    } catch (error) {
        yield put({ type: dashboardActions.DELETE_NOTES_FAILURE });
        yield put({ type: dashboardActions.SHOW_ALERTA_MSG, showAlerta: true, tipoAlerta: 'error', textoAlerta: 'Hubo un error, favor de contactar al administrador' });
    }
}
export function* deleteNotesSaga() {
    yield takeLatest(dashboardActions.DELETE_NOTES_REQUEST, deleteNotes);
}
