/* eslint-disable import/no-anonymous-default-export */
import * as dashboardActions from '../actions/dashboardActions';

const initialState = {
    page: 1,
    search: '',
    cleanForm: '',

    //Alerta
    showAlerta: false,
    tipoAlerta: '',
    textoAlerta: '',

    //Obtener lista usuarios
    fetchingListaUsuarios: false,
    listaUsuarios: [],
    listaUsuariosCantidad: 0,

    //Crear usuario
    showModalCrearUsuario: false,
    fetchingCrearUsuario: false,

    //Editar usuario
    showModalEditUser: false,
    fetchingEditUser: false,
    selectedUser: null,

    //Estado usuario
    fetchingEstadoUsuario: false,

    //Get Content
    fetchingListContent: false,
    listContent: [],
    listContentQty: 0,

    //Create Content
    fetchingCreateContent: false,
    showModalCreateContent: false,

    //Edit Content
    fetchingEditContent: false,
    showModalEditContent: false,
    selectedContent: [],

    //Delete Content
    fetchingDeleteContent: false,

    //Images
    fetchingCreateImages: false,
    fetchingEditImages: false,

    //Get Notes
    fetchingListNotes: false,
    listNotes: [],
    listNotesQty: 0,

    fetchingListNoteSlug: false,
    listNoteSlug: [],

    //Create Notes
    fetchingCreateNotes: false,
    showModalCreateNotes: false,

    //Edit Notes
    fetchingEditNotes: false,
    showModalEditNotes: false,
    selectedNotes: [],

    //Delete Notes
    fetchingDeleteNotes: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        //ALERTA
        case dashboardActions.SHOW_ALERTA_MSG:
            return { ...state, showAlerta: action.showAlerta, tipoAlerta: action.tipoAlerta, textoAlerta: action.textoAlerta };

        //CLEAN FORM
        case dashboardActions.CLEAN_FORM:
            return { ...state, cleanForm: action.cleanForm };

        //CHANGE PAGE VALUE
        case dashboardActions.CHANGE_PAGE_VALUE:
            return { ...state, page: action.page };

        //CHANGE SEARCH VALUE
        case dashboardActions.CHANGE_SEARCH_VALUE:
            return { ...state, search: action.search };

        //OBTENER LISTA USUARIOS
        case dashboardActions.OBTENER_USUARIOS_REQUEST:
            return { ...state, fetchingListaUsuarios: true };
        case dashboardActions.OBTENER_USUARIOS_SUCCESS:
            return { ...state, fetchingListaUsuarios: false, listaUsuarios: action.listaUsuarios, listaUsuariosCantidad: action.listaUsuariosCantidad };
        case dashboardActions.OBTENER_USUARIOS_FAILURE:
            return { ...state, fetchingListaUsuarios: false };

        //CREAR USUARIO
        case dashboardActions.SHOW_MODAL_CREAR_USUARIO:
            return {
                ...state,
                showModalCrearUsuario: action.showModalCrearUsuario,
                page: action.page,
                search: action.search,
            };

        case dashboardActions.CREAR_USUARIO_REQUEST:
            return { ...state, fetchingCrearUsuario: true };
        case dashboardActions.CREAR_USUARIO_SUCCESS:
            return { ...state, fetchingCrearUsuario: false };
        case dashboardActions.CREAR_USUARIO_FAILURE:
            return { ...state, fetchingCrearUsuario: false };

        //SHOW MODAL EDIT USER
        case dashboardActions.SHOW_MODAL_EDIT_USER:
            return {
                ...state,
                selectedUser: action.selectedUser,
                showModalEditUser: action.showModalEditUser,
                page: action.page,
                search: action.search,
            };
        //EDIT USER
        case dashboardActions.EDIT_USER_REQUEST:
            return {
                ...state,
                fetchingEditUser: true,
                page: action.page,
                search: action.search,
            };
        case dashboardActions.EDIT_USER_SUCCESS:
            return { ...state, fetchingEditUser: false };
        case dashboardActions.EDIT_USER_FAILURE:
            return { ...state, fetchingEditUser: false };

        //ESTADO USUARIO
        case dashboardActions.ESTADO_USUARIO_REQUEST:
            return { ...state, fetchingEstadoUsuario: true };
        case dashboardActions.ESTADO_USUARIO_SUCCESS:
            return { ...state, fetchingEstadoUsuario: false };
        case dashboardActions.ESTADO_USUARIO_FAILURE:
            return { ...state, fetchingEstadoUsuario: false };

        //CREATE CONTENT
        case dashboardActions.CREATE_CONTENT_REQUEST:
            return { ...state, fetchingCreateContent: true };
        case dashboardActions.CREATE_CONTENT_SUCCESS:
            return { ...state, fetchingCreateContent: false };
        case dashboardActions.CREATE_CONTENT_FAILURE:
            return { ...state, fetchingCreateContent: false };

        //GET CONTENT
        case dashboardActions.GET_CONTENT_REQUEST:
            return { ...state, fetchingListContent: true };
        case dashboardActions.GET_CONTENT_SUCCESS:
            return {
                ...state,
                fetchingListContent: false,
                listContent: action.listContent,
                listContentQty: action.listContentQty,
            };
        case dashboardActions.GET_CONTENT_FAILURE:
            return { ...state, fetchingListContent: false };

        //SHOW MODAL CREATE CONTENT
        case dashboardActions.SHOW_MODAL_CREATE_CONTENT:
            return {
                ...state,
                showModalCreateContent: action.showModalCreateContent,
                page: action.page,
                search: action.search,
            };

        //SHOW MODAL EDIT CONTENT
        case dashboardActions.SHOW_MODAL_EDIT_CONTENT:
            return {
                ...state,
                selectedContent: action.selectedContent,
                showModalEditContent: action.showModalEditContent,
                page: action.page,
                search: action.search,
            };
        //UPDATE CONTENT
        case dashboardActions.UPDATE_CONTENT_REQUEST:
            return { ...state, fetchingEditContent: true };
        case dashboardActions.UPDATE_CONTENT_SUCCESS:
            return { ...state, fetchingEditContent: false };
        case dashboardActions.UPDATE_CONTENT_FAILURE:
            return { ...state, fetchingEditContent: false };

        //DELETE CONTENT
        case dashboardActions.DELETE_CONTENT_REQUEST:
            return { ...state, fetchingDeleteContent: true };
        case dashboardActions.DELETE_CONTENT_SUCCESS:
            return { ...state, fetchingDeleteContent: false };
        case dashboardActions.DELETE_CONTENT_FAILURE:
            return { ...state, fetchingDeleteContent: false };

        //GET IMAGES
        case dashboardActions.GET_IMAGES_BY_CONTENT_REQUEST:
            return { ...state, fetchingImagesByContent: true };
        case dashboardActions.GET_IMAGES_BY_CONTENT_SUCCESS:
            return { ...state, fetchingImagesByContent: false, imagesByContent: action.listContent };
        case dashboardActions.GET_IMAGES_BY_CONTENT_FAILURE:
            return { ...state, fetchingImagesByContent: false };

        //CREATE IMAGE
        case dashboardActions.CREATE_IMAGE_BY_CONTENT_REQUEST:
            return { ...state, fetchingCreateImages: true };
        case dashboardActions.CREATE_IMAGE_BY_CONTENT_SUCCESS:
            return { ...state, fetchingCreateImages: false };
        case dashboardActions.CREATE_IMAGE_BY_CONTENT_FAILURE:
            return { ...state, fetchingCreateImages: false };

        //EDIT IMAGE
        case dashboardActions.EDIT_IMAGE_BY_CONTENT_REQUEST:
            return { ...state, fetchingEditImages: true };
        case dashboardActions.EDIT_IMAGE_BY_CONTENT_SUCCESS:
            return { ...state, fetchingEditImages: false };
        case dashboardActions.EDIT_IMAGE_BY_CONTENT_FAILURE:
            return { ...state, fetchingEditImages: false };

        //CREATE NOTES
        case dashboardActions.CREATE_NOTES_REQUEST:
            return { ...state, fetchingCreateNotes: true };
        case dashboardActions.CREATE_NOTES_SUCCESS:
            return { ...state, fetchingCreateNotes: false };
        case dashboardActions.CREATE_NOTES_FAILURE:
            return { ...state, fetchingCreateNotes: false };

        //GET NOTES
        case dashboardActions.GET_NOTES_REQUEST:
            return { ...state, fetchingListNotes: true };
        case dashboardActions.GET_NOTES_SUCCESS:
            return { ...state, fetchingListNotes: false, listNotes: action.listNotes, listNotesQty: action.listNotesQty };
        case dashboardActions.GET_NOTES_FAILURE:
            return { ...state, fetchingListNotes: false };

        case dashboardActions.GET_NOTE_SLUG_REQUEST:
            return { ...state, fetchingListNoteSlug: true };
        case dashboardActions.GET_NOTE_SLUG_SUCCESS:
            return { ...state, fetchingListNoteSlug: false, listNoteSlug: action.listNoteSlug };
        case dashboardActions.GET_NOTE_SLUG_FAILURE:
            return { ...state, fetchingListNoteSlug: false };

        //SHOW MODAL CREATE NOTES
        case dashboardActions.SHOW_MODAL_CREATE_NOTES:
            return {
                ...state,
                showModalCreateNotes: action.showModalCreateNotes,
                page: action.page,
                search: action.search,
            };

        //SHOW MODAL EDIT NOTES
        case dashboardActions.SHOW_MODAL_EDIT_NOTES:
            return {
                ...state,
                selectedNotes: action.selectedNotes,
                showModalEditNotes: action.showModalEditNotes,
                page: action.page,
                search: action.search,
            };
        //UPDATE NOTES
        case dashboardActions.UPDATE_NOTES_REQUEST:
            return { ...state, fetchingEditNotes: true };
        case dashboardActions.UPDATE_NOTES_SUCCESS:
            return { ...state, fetchingEditNotes: false };
        case dashboardActions.UPDATE_NOTES_FAILURE:
            return { ...state, fetchingEditNotes: false };

        //DELETE NOTES
        case dashboardActions.DELETE_NOTES_REQUEST:
            return { ...state, fetchingDeleteNotes: true };
        case dashboardActions.DELETE_NOTES_SUCCESS:
            return { ...state, fetchingDeleteNotes: false };
        case dashboardActions.DELETE_NOTES_FAILURE:
            return { ...state, fetchingDeleteNotes: false };

        default:
            return state;
    }
};
