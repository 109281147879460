import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Col, Row, Divider, Spin } from 'antd';
import { ContainerDivider, IconContainer, ListNavbar, NavbarContainer } from './navbar.style';
import LogoSISSA from '../../../assets/logos/logotipo_SISSA_DIGITAL_blanco.svg';
const Navbar = () => {
    const [current, setCurrent] = useState('');
    const listContent = useSelector((state) => state.landingReducer.listContent);
    console.log('listContent', listContent);
    const params = useParams();
    return (
        <>
            <NavbarContainer>
                {listContent.length && !params.slug ? (
                    <Row justify='space-around' align='middle'>
                        <Col lg={5} onClick={() => setCurrent('nosotros')}>
                            <Row justify='space-around' align='middle'>
                                <Col>
                                    <a href='#nosotros'>
                                        <ListNavbar className='title' isActive={current === 'nosotros'}>
                                            {listContent[0].titulo}
                                        </ListNavbar>
                                    </a>
                                </Col>
                                <Col>
                                    <ContainerDivider>
                                        <Divider type='vertical' />
                                    </ContainerDivider>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={5} onClick={() => setCurrent('productos')}>
                            <Row justify='space-around' align='middle'>
                                <Col>
                                    <a href='#productos'>
                                        <ListNavbar className='title' isActive={current === 'productos'}>
                                            {listContent[1].titulo}
                                        </ListNavbar>
                                    </a>
                                </Col>
                                <Col>
                                    <ContainerDivider>
                                        <Divider type='vertical' />
                                    </ContainerDivider>
                                </Col>
                            </Row>
                        </Col>
                        {/* <Col lg={2} offset={1} onClick={() => setCurrent('unete')}>
                        <Row justify='space-around' align='middle'>
                            <Col>
                                <ListNavbar className='title' isActive={current === 'unete'}>
                                    Únete
                                </ListNavbar>
                            </Col>
                            <Col>
                                <ContainerDivider>
                                    <Divider type='vertical' />
                                </ContainerDivider>
                            </Col>
                        </Row>
                    </Col> */}
                        {/* <Col lg={4} onClick={() => setCurrent('clientes')}>
                        <Row justify='space-around' align='middle'>
                            <Col>
                                <a href='#clientes'>
                                    <ListNavbar className='title' isActive={current === 'clientes'}>
                                        Clientes
                                    </ListNavbar>
                                </a>
                            </Col>
                            <Col>
                                <ContainerDivider>
                                    <Divider type='vertical' />
                                </ContainerDivider>
                            </Col>
                        </Row>
                    </Col> */}
                        <Col lg={4} xl={1}>
                            <IconContainer>
                                <img src={listContent[2].imagenesByIdContenido[0].url} className='logoHeader' alt='Logo SISSA' />
                            </IconContainer>
                        </Col>

                        {/* <Col lg={3} onClick={() => setCurrent('herramientas')}>
                        <Row justify='space-around' align='middle'>
                            <Col>
                                <ListNavbar className='title' isActive={current === 'herramientas'}>
                                    Herramientas
                                </ListNavbar>
                            </Col>
                            <Col>
                                <ContainerDivider>
                                    <Divider type='vertical' />
                                </ContainerDivider>
                            </Col>
                        </Row>
                    </Col> */}
                        <Col lg={5} onClick={() => setCurrent('servicios')}>
                            <Row justify='space-around' align='middle'>
                                <Col>
                                    <ContainerDivider>
                                        <Divider type='vertical' />
                                    </ContainerDivider>
                                </Col>
                                <Col>
                                    <a href='#servicios'>
                                        <ListNavbar className='title' isActive={current === 'servicios'}>
                                            {listContent[3].titulo}
                                        </ListNavbar>
                                    </a>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={5} onClick={() => setCurrent('noticias')}>
                            <Row justify='space-around' align='middle'>
                                <Col>
                                    <ContainerDivider>
                                        <Divider type='vertical' />
                                    </ContainerDivider>
                                </Col>
                                <Col>
                                    <a href='#noticias'>
                                        <ListNavbar className='title' isActive={current === 'noticias'}>
                                            {listContent[4].titulo}
                                        </ListNavbar>
                                    </a>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                ) : (
                    <Row justify='space-around' align='middle'>
                        <Col lg={4} xl={1}>
                            <IconContainer>
                                <a href='/'>
                                    <img src={LogoSISSA} className='logoHeader' alt='Logo SISSA' />
                                </a>
                            </IconContainer>
                        </Col>
                    </Row>
                )}
            </NavbarContainer>
        </>
    );
};

export default Navbar;
