import { useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import React from 'react';
import logoFooter from '../../assets/logos/LOGO-Footer.png';
import placaCMMI from '../../assets/logos/PlacaCMMI.png';
import moreInfo from '../../assets/logos/botonTexto.png';
import { Background, FooterLayour, IconContainer } from './footer.style';
import { Typography, Spin } from 'antd';
import HTMLRenderer from 'react-html-renderer';

const { Text } = Typography;

const Footer = () => {
    const fetchingGetContent = useSelector((state) => state.landingReducer.fetchingGetContent);
    const listContent = useSelector((state) => state.landingReducer.listContent);
    return (
        <Spin spinning={fetchingGetContent}>
            <Background>
                <Row justify='space-around' align='middle'>
                    <Col xs={23} lg={23}>
                        {listContent.length ? (
                            <Row justify='space-between' align='middle'>
                                <Col lg={0} md={0} xs={23}>
                                    <IconContainer className='fontFooter'>
                                        <HTMLRenderer html={listContent[23].titulo} />
                                    </IconContainer>
                                </Col>
                                <Col lg={4} md={6} xs={6}>
                                    <img className='logoFooter' src={listContent[22].imagenesByIdContenido[0].url} alt='logo' />
                                </Col>
                                <Col lg={4} md={6} xs={0}>
                                    <IconContainer className='fontFooter'>
                                        <HTMLRenderer html={listContent[23].titulo} />
                                    </IconContainer>
                                </Col>

                                <Col lg={4} md={6} xs={6}>
                                    <img className='shadowCMMI' src={listContent[24].imagenesByIdContenido[0].url} alt='CMMI' />
                                </Col>
                                <Col lg={2} md={6} xs={6}>
                                    <a href={listContent[25].imagenesByIdContenido[0].href} target='_blank'>
                                        <img className='shadowBtn' src={listContent[25].imagenesByIdContenido[0].url} alt='CMMI' />
                                    </a>
                                </Col>
                            </Row>
                        ) : (
                            false
                        )}
                    </Col>
                </Row>
            </Background>
            <FooterLayour>
                <Row justify='space-between' align='middle' style={{ padding: '1em' }}>
                    <Col lg={{ span: 12, offset: 0 }} md={{ span: 10, offset: 1 }} xs={24} style={{ textAlign: 'left' }}>
                        <Text style={{ color: 'white', fontSize: '.7em' }} className='copyrigth'>
                            © SISSADIGITAL todos los derechos reservados
                        </Text>
                    </Col>
                    <Col lg={11} md={11} xs={24} style={{ textAlign: 'right' }}>
                        <Text style={{ color: 'white', fontSize: '.7em' }} className='privacy'>
                            Aviso legal y política de privacidad
                        </Text>
                    </Col>
                </Row>
            </FooterLayour>
        </Spin>
    );
};

export default Footer;
