import styled from "styled-components";

export const Container = styled.div`
    .tabDashboard {
        text-align: center;
        justify-content: center;
        color: #16296d;
        font-size: 1em;
    }

    .ant-input-number {
        border-radius: 2em;
        width: 100%;
    }

    .iconLayout {
        position: relative;
        width: 25px;
        height: 25px;

        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: #1747ca;
        background-color: #f2f2f2;
        font-size: 1em;
        box-shadow: inset -1px -1px 2px rgba(72, 83, 37, 0.2), 2px 2px 5px rgba(72, 83, 37, 0.2);
        border-radius: 50%;
        transition: all;
        transition-duration: 700ms;
        transition-timing-function: ease-in-out;
        transition-delay: 80ms;
    }
    .efectoTab {
        position: relative;
        width: 50px;
        height: 50px;
        margin-right: 0px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: #203747;
        background-color: #f2f2f2;
        font-size: 2em;
        box-shadow: inset -1px -1px 2px rgba(72, 83, 37, 0.2), 2px 2px 5px rgba(72, 83, 37, 0.2);
        border-radius: 50%;
        transition: all;
        transition-duration: 480ms;
        transition-timing-function: ease-in-out;
        transition-delay: 80ms;
    }
    .efectoTab:hover {
        background-color: #203747;
        color: #f2f2f2;
        transform: translateX(0px) rotate(360deg);
        -webkit-transform: translateX(0px) rotate(360deg);
    }

    .ant-tabs .ant-tabs-ink-bar {
        background-color: #203747;
    }

    :where(.css-dev-only-do-not-override-1n7nwfa).ant-tabs-top > .ant-tabs-nav::before,
    :where(.css-dev-only-do-not-override-1n7nwfa).ant-tabs-bottom > .ant-tabs-nav::before,
    :where(.css-dev-only-do-not-override-1n7nwfa).ant-tabs-top > div > .ant-tabs-nav::before,
    :where(.css-dev-only-do-not-override-1n7nwfa).ant-tabs-bottom > div > .ant-tabs-nav::before {
        border-bottom: transparent;
    }

    .divCard {
        background-color: #e4e4e4;
        border-radius: 2em;
    }
    .headerLista {
        color: #43425d;
        font-size: 1.6em;
        font-weight: bold;
        text-align: left;
    }
    .cardCatalogos {
        border-radius: 2em;
        border-color: #e4e3e3;
        background-color: #f2f2f2;
    }
    .cardCatalogos:hover {
        border-width: 0.1em;
        box-shadow: 0px 2px 5px 0px #1747ca80;
        border-color: #16296d;
    }

    :where(.css-dev-only-do-not-override-1n7nwfa).ant-card .ant-card-head {
        background-color: #e4e3e3;
        border-radius: 1.75em 1.75em 0 0;
    }

    .ant-input-group > .ant-input:first-child {
        border-top-left-radius: 2em;
        border-bottom-left-radius: 2em;
    }

    .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
        border-top-right-radius: 2em;
        border-bottom-right-radius: 2em;
    }

    .ant-modal .ant-modal-content {
        border-radius: 2em;
    }

    .ant-input-affix-wrapper {
        border-radius: 2em;
    }

    .ant-input {
        border-radius: 2em;
    }

    .ant-modal .ant-modal-title {
        color: #2c4977;
    }
    .ant-modal .ant-modal-close {
        color: #2c4977;
    }
    .ant-input-search :where(.css-dev-only-do-not-override-1n7nwfa).ant-input-group .ant-input-affix-wrapper:not(:last-child) {
        border-start-start-radius: 2em;
        border-end-start-radius: 2em;
    }
    /* .ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
  border-start-start-radius: 2em;
} */

    .btnPrimario {
        border-color: #2c4977;
        border-radius: 3rem;
        color: white;
        background-color: #2c4977;
        overflow: hidden;
        position: relative;
        text-decoration: none;
        transition: 0.2s transform ease-in-out;
        will-change: transform;
        z-index: 0;
        width: 100%;
    }
    .btnPrimario::after {
        background-color: #e4e3e3;
        color: white;
        border-radius: 3rem;
        content: "";
        display: block;
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        transform: translate(-100%, 0) rotate(10deg);
        transform-origin: top left;
        transition: 0.2s transform ease-out;
        will-change: transform;
        z-index: -1;
    }
    .btnPrimario:hover::after {
        transform: translate(0, 0);
    }
    .btnPrimario:hover:not([disabled]) {
        border: 2px solid #2c4977 !important;
        color: #2c4977 !important;
        transform: scale(1.05);
        will-change: transform;
    }
    .btnPrimario:disabled {
        background-color: #e4e3e3;
        color: rgba(0, 0, 0, 0.402);
    }

    .marginBoton {
        padding-top: 1.2em;
    }

    .iconoPrefix {
        color: rgba(0, 0, 0, 0.402);
    }
`;
