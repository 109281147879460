//File that wrap every saga file
import { all, fork } from 'redux-saga/effects';
import * as loginSaga from './loginSaga';
import * as landingSaga from './landingSaga';
import * as dashboardSaga from './dashboardSaga';

export default function* rootSaga() {
    var allSagas = Object.assign(loginSaga, dashboardSaga, landingSaga);

    yield all([...Object.values(allSagas)].map(fork));
}
