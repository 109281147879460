import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spin, Input, Button, Form, Row, Col, Upload, Tooltip, message, Descriptions } from 'antd';
import { FontSizeOutlined, AlignLeftOutlined, CloudUploadOutlined, SaveOutlined } from '@ant-design/icons';
import ModalUI from '../../UI/modalUI/modalUI';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-build-classic-base64-upload/build/ckeditor';

const { Dragger } = Upload;
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

const ModalEditContent = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const showModalCreateNotes = useSelector((state) => state.dashboardReducer.showModalCreateNotes);
    const fetchingCreateNotes = useSelector((state) => state.dashboardReducer.fetchingCreateNotes);
    const page = useSelector((state) => state.dashboardReducer.page);
    const search = useSelector((state) => state.dashboardReducer.search);
    const cleanForm = useSelector((state) => state.dashboardReducer.cleanForm);

    const [fileList, setFileList] = useState(null);
    const [loadingUpload, setLoadinUpload] = useState(false);
    const [htmlEditor, setHtmlEditor] = useState('');

    useEffect(() => {
        form.setFieldsValue();
        if (!showModalCreateNotes) {
            form.resetFields();
        }
    }, [showModalCreateNotes, form]);

    const handleCancel = () => {
        form.resetFields();
        setFileList(null);
        setLoadinUpload(false);
        setHtmlEditor('');
        dispatch({ type: 'SHOW_MODAL_CREATE_NOTES', showModalCreateNotes: false, page, search });
    };
    if (cleanForm === 'addNote') {
        handleCancel();
        dispatch({ type: 'CLEAN_FORM', cleanForm: '' });
    }
    console.log('htmlEditor :>> ', htmlEditor);
    const onFinish = (data) => {
        console.log('data', data, htmlEditor);
        data.imagen.file.base64 = '';
        data.htmlEditor = htmlEditor;
        let reader = new FileReader();
        reader.onloadend = (e) => {
            data.imagen.file.base64 = e.target.result;
            dispatch({ type: 'CREATE_NOTES_REQUEST', data, page, search });
        };
        reader.readAsDataURL(data.imagen.file.originFileObj);
    };

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess('ok');
        }, 1000);
    };

    const props = {
        name: 'file',
        multiple: false,
        accept: '.png, .jpg, .svg, .jpeg',
        customRequest: dummyRequest,
        fileList: fileList,
        onChange(info) {
            const { status } = info.file;
            let fileList = [...info.fileList];

            // 1. Limit the number of uploaded files
            // Only to show two recent uploaded files, and old ones will be replaced by the new
            fileList = fileList.slice(-1);
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            } else {
                setFileList(fileList);
                setLoadinUpload(true);
            }
            if (status === 'done') {
                message.success(`El archivo "${info.file.name}" se subió de manera correcta.`);
                setFileList(fileList);
                setLoadinUpload(false);
            } else if (status === 'error') {
                message.error(`${info.file.name} falló al cargarse.`);
                setFileList(fileList);
                setLoadinUpload(false);
            }
        },
        onRemove() {
            setFileList([]);
        },
    };
    const validateMessages = {
        required: 'Favor de llenar el campo!',
    };

    return (
        <ModalUI title={'Crear nota'} open={showModalCreateNotes} hideModal={handleCancel} width={900}>
            <Spin spinning={fetchingCreateNotes}>
                <Form {...layout} form={form} onFinish={onFinish} validateMessages={validateMessages}>
                    <Row type='flex' justify='end'>
                        <Col span={24}>
                            <Row>
                                <Col span={24}>
                                    <Descriptions bordered>
                                        <Descriptions.Item label='Tipo de contenido'>Nota</Descriptions.Item>
                                    </Descriptions>
                                    <br />
                                </Col>
                                <Row justify='space-around'>
                                    <Col lg={11} xs={23}>
                                        <Form.Item label='Título' name='titulo' rules={[{ required: true }]}>
                                            <Input prefix={<FontSizeOutlined className='iconoPrefix' />} placeholder='Título' />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={11} xs={23}>
                                        <Form.Item label='Descripción de metadata' name='descripcion' rules={[{ required: true }]}>
                                            <Input prefix={<AlignLeftOutlined className='iconoPrefix' />} placeholder='Descripción' />
                                        </Form.Item>
                                    </Col>

                                    <Col lg={11} xs={23}>
                                        <Form.Item label='Imagen de metadata' name='imagen' rules={[{ required: true }]}>
                                            <Dragger {...props}>
                                                <p className='ant-upload-drag-icon'>
                                                    <CloudUploadOutlined />
                                                </p>
                                                <p className='ant-upload-text'>Clic o arrastra un archivo para cargar</p>
                                            </Dragger>
                                        </Form.Item>
                                    </Col>
                                    <Col lg={23} xs={23}>
                                        <Form.Item label='Contenido' name='contenido' rules={[{ required: true }]}>
                                            <CKEditor
                                                editor={ClassicEditor}
                                                onChange={(event, editor) => {
                                                    console.log({ event, editor });
                                                }}
                                                onBlur={(event, editor) => {
                                                    const data = editor.getData();
                                                    setHtmlEditor(data);
                                                    console.log('Blur.', editor);
                                                    setLoadinUpload(false);
                                                }}
                                                onFocus={(event, editor) => {
                                                    console.log('Focus.', editor);
                                                    setLoadinUpload(true);
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Row>
                        </Col>
                        <Col lg={1} xs={1} className='marginBoton'>
                            <br />
                            <Tooltip title='Guardar' placement='left'>
                                <Button className='btnPrimarioIcono' disabled={loadingUpload} shape='circle' htmlType='submit' icon={<SaveOutlined />} />
                            </Tooltip>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </ModalUI>
    );
};

export default ModalEditContent;
