import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import HTMLRenderer from 'react-html-renderer';
import Carousel from 'react-multi-carousel';
import { Col, Row, Card, Spin, Badge, Tooltip } from 'antd';
import { Space, Title, ContainerCarousel, Divider } from './team.style';
import 'react-multi-carousel/lib/styles.css';
import moment from 'moment';

const { Meta } = Card;
const Team = () => {
    const dispatch = useDispatch();

    const [page, setPage] = useState(1);
    const listNotes = useSelector((state) => state.dashboardReducer.listNotes);
    const fetchingListNotes = useSelector((state) => state.dashboardReducer.fetchingListNotes);
    const listNotesQty = useSelector((state) => state.dashboardReducer.listNotesQty);
    const [infinite, setInfinite] = useState(false);
    const fetchingGetContent = useSelector((state) => state.landingReducer.fetchingGetContent);
    const listContent = useSelector((state) => state.landingReducer.listContent);

    useEffect(() => {
        dispatch({ type: 'GET_NOTES_REQUEST', page, offset: 12 });
    }, [page, dispatch]);

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 2000 },
            items: 3,
        },
        desktop: {
            breakpoint: { max: 2000, min: 1024 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };

    console.log('listNotes :>> ', listNotes);

    const Cards = (item) => {
        console.log('item :>> ', item);
        return (
            <Col span={23}>
                <a href={'/blog/' + item.item.titulo}>
                    <Badge.Ribbon text={'Última modificación ' + moment(item.fechaActualizacion).startOf('day').fromNow()} color={'#254a74'}>
                        <Card loading={fetchingListNotes} hoverable style={{ width: '100%' }} cover={<img alt='example' className='coverCard' src={item.item.imagen} />}>
                            <Meta
                                title={
                                    <Tooltip title={item.item.titulo} placement='topLeft'>
                                        {item.item.titulo}
                                    </Tooltip>
                                }
                                description={item.item.descripcion}
                            />
                        </Card>
                    </Badge.Ribbon>
                    <Space />
                </a>
            </Col>
        );
    };
    const afterChangeCarousel = (value) => {
        console.log('value en after:>> ', value, listNotesQty / 3);
        setInfinite(value >= listNotesQty / 3);
    };
    return (
        <Spin spinning={fetchingGetContent}>
            {listContent.length ? (
                <Row justify='space-around' align='middle'>
                    <Col lg={{ span: 6 }} xs={{ span: 2 }} md={{ span: 6 }}>
                        <Divider />
                    </Col>
                    <Col lg={{ span: 10 }} xs={{ span: 20 }} md={{ span: 12 }}>
                        <Title id='noticias'>
                            <HTMLRenderer html={listContent[20].titulo} />
                        </Title>
                    </Col>
                    <Col lg={{ span: 6 }} xs={{ span: 2 }} md={{ span: 6 }}>
                        <Divider />
                    </Col>
                </Row>
            ) : (
                false
            )}
            <Space />
            <Row justify='space-around' align='middle'>
                <Col xs={23} lg={22}>
                    <ContainerCarousel>
                        <Carousel keyBoardControl={true} responsive={responsive} beforeChange={afterChangeCarousel}>
                            {listNotes.map((item) => (
                                <Cards key={item.idNota} item={item} />
                            ))}
                        </Carousel>
                    </ContainerCarousel>
                </Col>
            </Row>
        </Spin>
    );
};

export default Team;
