import { BrowserRouter, Navigate, Route, Routes, useParams } from 'react-router-dom';
import DashboardContainer from '../../pages/dashboard/dashboardContainer';
import LandingPage from '../../pages/landingPage/landingPage';
import LoginContainer from '../../layouts/login/loginContainer';
import BlogPage from '../../pages/blog/blogPage';

function PrivateRoute({ children }) {
    let isAuthenticated = sessionStorage.getItem('isAuthenticated') === 'true';
    console.log('isAuthenticated en private', isAuthenticated);

    if (!isAuthenticated) {
        // not logged in so redirect to login page with the return url
        console.log('Entró a redirigir editor');
        return <Navigate to='/editor' />;
    }
    // authorized so return child components
    return children;
}

function App() {
    const Dynamic = () => {
        const params = useParams();
        return <BlogPage slug={params.slug} />;
    };

    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<LandingPage />} />
                <Route path='/editor' element={<LoginContainer />} />
                <Route path='blog/:slug' element={<Dynamic />} />
                <Route
                    path='/dashboard'
                    element={
                        <PrivateRoute>
                            <DashboardContainer />
                        </PrivateRoute>
                    }
                />
                <Route path='*' element={<Navigate replace to='/' />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
