import { Modal } from "antd";
import { Container } from "./modalUI.style";

const ModalUI = ({ open, hideModal, title, width, children }) => {
    return (
        <>
            <Modal destroyOnClose title={title} open={open} onCancel={hideModal} footer={false} width={width}>
                <Container>{children}</Container>
            </Modal>
        </>
    );
};
export default ModalUI;
