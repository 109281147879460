import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import 'moment/locale/es';
import { Row, Col, Card, Tooltip, Input, Button, Modal, Pagination, Spin } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { CardNotes, IconDelete, IconEdit } from './notes.style';
import ModalAddNote from '../addNote/addNote';
import ModalEditNote from '../editNote/editNote';

const { Search } = Input;
const confirm = Modal.confirm;

const Notes = () => {
    const dispatch = useDispatch();

    const [page, setPage] = useState(1);
    const [search, setsearch] = useState('');

    useEffect(() => {
        dispatch({ type: 'GET_NOTES_REQUEST', page, search, offset: 12 });
    }, [page, search, dispatch]);

    const listNotes = useSelector((state) => state.dashboardReducer.listNotes);
    const fetchingListNotes = useSelector((state) => state.dashboardReducer.fetchingListNotes);
    const listNotesQty = useSelector((state) => state.dashboardReducer.listNotesQty);

    const onCreateNotes = () => {
        dispatch({
            type: 'SHOW_MODAL_CREATE_NOTES',
            showModalCreateNotes: true,
            page,
            search,
        });
    };
    const onEditNotes = (value) => {
        console.log('value', value);
        dispatch({
            type: 'SHOW_MODAL_EDIT_NOTES',
            showModalEditNotes: true,
            selectedNotes: value,
            page,
            search,
        });
    };
    const changePage = (pagination) => {
        console.log('pagination', pagination);
        setPage(pagination);
    };
    const handleDelete = (key) => {
        console.log('key', key);
        confirm({
            title: '¿Estás seguro que deseas eliminar la nota "' + key.titulo + '"?',
            content: '',
            okText: 'Sí',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                dispatch({
                    type: 'DELETE_NOTES_REQUEST',
                    page,
                    search,
                    selectedNotes: key,
                });
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    return (
        <Spin spinning={fetchingListNotes}>
            <Card className='cardCatalogos' title={<div className='header'>Administración de notas</div>}>
                <Row type='flex' justify='end' gutter={[8, 8]}>
                    <Col span={1}>
                        <Tooltip title='Nueva Nota' placement='topRight'>
                            <Button className='btnPrimario' shape='circle' onClick={onCreateNotes} icon={<PlusOutlined />} />
                        </Tooltip>
                    </Col>
                    <Col span={24}>
                        <Row type='flex' justify='space-around'>
                            {listNotes.map((item) => (
                                <Col lg={7} md={11} xs={22} key={item.idNota}>
                                    <CardNotes>
                                        <Row type='flex' justify='space-around'>
                                            <Col span={11}>
                                                <p className='category'>{item.categoria}</p>
                                            </Col>
                                            <Col span={11}>
                                                <IconEdit onClick={() => onEditNotes(item)}>
                                                    <EditOutlined />
                                                </IconEdit>
                                            </Col>
                                            <Col span={24}>
                                                <h1 className='title'>{item.titulo}</h1>
                                                <p className='description'>{item.descripcion}</p>
                                                <Row type='flex' justify='space-around'>
                                                    <Col span={17}>
                                                        <p className='date'>
                                                            Último movimiento
                                                            <span> {moment(item.fechaActualizacion).startOf('day').fromNow()}</span>
                                                        </p>
                                                    </Col>
                                                    <Col span={5}>
                                                        <IconDelete onClick={() => handleDelete(item)}>
                                                            <DeleteOutlined />
                                                        </IconDelete>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </CardNotes>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                    <Pagination simple total={listNotesQty} current={page} onChange={changePage} />
                </Row>
            </Card>
            <ModalAddNote />
            <ModalEditNote />
        </Spin>
    );
};

export default Notes;
